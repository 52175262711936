import { useState } from "react";
import { getCookie } from "../scripts/xhrf";


// Userinfo, die im Photorating, Videorating und der Modelverification angezeigt wird
export function NewUserInfo({acc,maxTextHeight,pos,showOrigin}){
  let src = "";
  if(acc.avatarUrl===null){
    src = acc.profilePictureUrls[0];
  }else{
    src = acc.avatarUrl;
  }
  let isNotVideoPage = !window.location.pathname.includes("videoRating");
  return(
    <div className={pos + ` bg-white dark:bg-slate-700 rounded-3xl relative grid grid-cols-7 p-4`}>
      <img className="object-scale-down col-span-1 col-start-1 p-1 h-[10vh]" src={src} alt="Profilbild"></img>
      <div className="col-span-2 col-start-2 pl-2">
        <div className="">
          <p className={`mr-1 px-1 text-xs text-white border-[1px] rounded-sm inline ${(acc.isVerified ? "bg-green-500 border-green-500": "bg-red-600 border-red-600")} font-bold`}>{(acc.isVerified? "VERIFIED": "NOT VERIFIED")}</p>
          { (isNotVideoPage && acc.isNew) && <p className={`mr-1 px-1 text-xs text-white border-[1px] rounded-sm border-black inline-block bg-black font-bold`}>{(acc.isNew ? "NEW": "")}</p>}
          { (isNotVideoPage && acc.isPremiumCooperation) && <p className={`mr-1 px-1 text-xs text-white border-[1px] rounded-sm border-black inline-block bg-black dark:border-white font-bold`}>{(acc.isPremiumCooperation ? "PREMIUM": "")}</p>}
          { acc.platforms.includes("VISIT-X") && <p className={`mr-1 px-1 text-xs text-white border-[1px] rounded-sm border-black inline-block bg-black`}>{"VISIT-X"}</p>}
          { acc.platforms.includes("VXPAGES") && <p className={`mr-1 px-1 text-xs text-white border-[1px] rounded-sm border-black inline-block bg-black`}>{"VX-PAGES"}</p>}
          { acc.isInternal && <p className={`mr-1 px-1 text-xs text-black inline bg-yellow-300 border-[1px] rounded-sm border-yellow-300 font-bold`}>{(acc.isInternal ? "INTERNAL ACC": "")}</p>}
        </div>
        {getCookie('internal') === "X" ? <a className="font-bold" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+acc.userId} target="_blank" rel="noreferrer">{acc.userName + " | ID: " + acc.userId}</a>
        : <p className="font-bold">{acc.userName + " | ID: " + acc.userId}</p>}
        <p className="font-bold text-[13px]">{acc.profileGender + "/" + acc.profileSexuality}</p>
        <div className="text-[13px]">
          <p className="inline text-disabledGreyText"> Registered:</p>
          <p className="inline font-bold">{" " + acc.signupDate.substring(0,10)}</p>
        </div>
        <div className="text-[13px]">
          {showOrigin ?   // Shows Acc Manager or Origin site(for Modelverification)
            <div className={acc.originPlatform === "SEXOLE" ? "bg-yellow-200" : ""}>
            <p className="inline text-gray-400"> Platform:</p>
            <p className="inline">{" " + acc.originPlatform}</p>
            </div>
          :
            <div>
              <p className="inline text-gray-400"> Acc Mgr:</p>
              <p className="inline">{" " + (acc.isActiveAccounting ? acc.accountManager : "")}</p>
            </div>
          }
          </div>
      </div>
      <div className="col-span-4 col-start-4 mr-2">
        <div className={maxTextHeight}>
          <p className="text-disabledGreyText text-[13px]">Main Comment:</p>
          <p className="text-red-500 h-[5vh] overflow-y-scroll">{acc.mainComment}</p>
        </div>
        <div className={maxTextHeight}>
          <p className="text-disabledGreyText text-[13px]">Privilege Comment:</p>
          <p className="text-red-500 h-[5vh] overflow-y-scroll">{acc.privilegeComment}</p>
        </div>
      </div>
    </div>
  );
}

// Tag der für alle Tagfelder verwendet wird
export function Tag({text,suspected,newTags,oc}){
  // Checks for Badwords in Tags
  let isBad = false;
  let isNew = false;
  if(suspected.includes(text)){
    isBad = true;
  }else if(newTags.includes(text)){
    isNew = true;
  }
  return(
    <li className={`${isBad ? "bg-yellow-400" : (isNew ? "bg-zanBlue text-white":"bg-disabledGrey text-black")} pl-[8px] pr-[6px] m-1 rounded-full inline-block h-[30px]`}>
      <p className="inline mr-1">{text}</p>
      <button className="inline h-5 w-5 translate-y-1" onClick={() => oc(text)}><img className={`${isNew ? "" : "invert"}`} alt="" src="/icons/cancel.png"></img></button>
    </li>
  );
}

// Das Rejectoverlay mit vorgefertigten Ablehngründen
export function RejectOverlay({reasons,appStatus,setAppStatus,oc}){
  const [rejectLang,setRejectLang] = useState("DE");
  let rr = reasons.map((i,index) => <button className="border-2 border-black w-40 my-0.5" key={index} onClick={(e)=>inputText(i.textDE,i.textEN)}>{i.label}</button>);
  // Imputs the predefined texts into the textfield
  function inputText(a,b){
    setAppStatus({
      ...appStatus,
      rejectReasonDE : a,
      rejectReasonEN : b
    });
  }
  // Closes the Overlay and deletes the current rejectreasons
  function closeOverlay(){
    setAppStatus({
      ...appStatus,
      rejectOverlay : false,
      rejectReasonDE : "",
      rejectReasonEN : ""
    });
    setRejectLang("DE");
  }
  // Selects the currently displayed rejectreason language
  function selectLanguage(lang){
    setRejectLang(lang);
  }
  // Updates the text when written into the textfield
  function changeText(e){
    let de = appStatus.rejectReasonDE;
    let en = appStatus.rejectReasonEN;
    if(rejectLang==="DE"){
      de = e.target.value;
    }else{
      en = e.target.value;
    }
    setAppStatus({
      ...appStatus,
      rejectReasonDE : de,
      rejectReasonEN : en
    });
  }
  return(
  <div className="absolute top-[30vh] left-[35vw] h-[30vh] w-[30vw] bg-red-400 border-4 border-black text-black">
    <p className="absolute top-2 left-3 font-bold text-xl">Rejection Reason</p>
    <button className="absolute top-1 right-1 font-bold text-xl" onClick={closeOverlay}>X</button>
    <button className={`absolute top-10 left-3 w-9 border-2 border-black ${(rejectLang==="DE"?"bg-red-600":"")}`} onClick={(e)=>selectLanguage("DE")}>DE</button>
    <button className={`absolute top-10 left-12 w-9 border-2 border-black ${(rejectLang==="EN"?"bg-red-600":"")}`} onClick={(e)=>selectLanguage("EN")}>EN</button>
    <textarea className="absolute top-16 left-3 w-80 h-32 resize-none border-2 border-black" onChange={(e)=>changeText(e)}
              value={(rejectLang==="DE"? appStatus.rejectReasonDE : appStatus.rejectReasonEN)}></textarea>
    <p className="absolute top-9 right-2 w-40 text-center">Presets</p>
    <div className="absolute top-16 right-2 w-40">
      {rr}
    </div>
    <button className="absolute bottom-5 left-3 border-2 border-black" onClick={()=>oc(appStatus.rejectReasonDE,appStatus.rejectReasonEN)}>Reject</button>
  </div>
  );
}

// Überprüft die vorausgewählte FSK-Stufe bei Profilbildern im Photorating
export function checkType(type,fsk){
  let fsk12 = "51_3";
  let fsk16 = "51_1";
  let fsk18 = "51_2";
  let types = [fsk12,fsk16,fsk18]
  if(types.includes(type)){
    if(fsk12 === type){
      return 12;
    }else if(fsk16 === type){
      return 16;
    }else{
      return 18;
    }
  }
  return fsk;
}