import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { getFirstCat, getNextTexts, saveTexts, getCookie } from "../scripts/xhrf";
import Highlighter from "react-highlight-words";

export default function TextRateApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 6;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in txt werden die Texte gespeichert
  const [txt,setTxt] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    product : "ALL",
    status : "new",
    limit : 4,
    page : 0,
    rating : Array(4).fill("accepted"),
    modelId : ""
  });
  
  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

  // fetches Data on load
  useEffect(()=>{
    async function getData(){
      if(secondaryCategory===-1){
        return;
      }else{
        let pr = appStatus.product;
        let st = appStatus.status;
        let lt = appStatus.limit;
        let usr = appStatus.modelId;
        let t = await getNextTexts(pr,st,lt,usr);
        if(t.errors !== undefined){
          alert("Fehler: " + t.errors[0].message);
        }
        console.log(t);
        setTxt(t.data.textRating);
        setAppStatus({
          product : pr,
          status : st,
          limit : lt,
          page : 0,
          rating : t.data.textRating.texts.map((t)=>(t.status==="accepted"?"accepted":(st==="rejected"?t.rejectionReason??"TEXT_OTHER" : "accepted"))),
          modelId : usr
        });
        setLoading(false);
        setDoRefresh(false);
      }
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.product,appStatus.status,appStatus.limit,appStatus.modelId,doRefresh]);

  let contentF = <NewTextRating
                 txt={txt}
                 setTxt={setTxt}
                 appStatus={appStatus}
                 setAppStatus={setAppStatus}
                 loading={loading}
                 setDoRefresh={setDoRefresh}
               />;
       

  return(
    <div className="dark:bg-gray-800 dark:text-white min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No texts to rate"}</p>}
    </div>
  );
}

// Hauptkomponente für das Textrating
function NewTextRating({txt,setTxt,appStatus,setAppStatus,loading,setDoRefresh}){

  const [modelIdPreview,setModelIdPreview] = useState("");

  function setNewTitles(nt,i){
    let t = txt.texts;
    t[i].title = nt;
    setTxt({
      ...txt,
      texts : t
    });
  }
  function setNewTexts(nt,i){
    let t = txt.texts;
    t[i].text = nt;
    setTxt({
      ...txt,
      texts : t
    });
  }

  function changeProduct(e){
    setAppStatus({
      ...appStatus,
      product : e.target.value
    });
  }
  function changeStatus(e){
    setAppStatus({
      ...appStatus,
      status : e.target.value
    });
  }
  function changeLimit(e){
    setAppStatus({
      ...appStatus,
      limit : e.target.value
    });
  }

  function changeModelId(e){
    setModelIdPreview(e.target.value);
  }
  function applySearch(){
    console.log(appStatus);
    setAppStatus({
      ...appStatus,
      modelId : modelIdPreview
    });
    //setDoRefresh(true);
  }
  
  let gd = null;
  if(loading){
    gd =  <div className="w-full h-[10vh] bg-zanBg dark:bg-slate-700">
            <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  }else{
    gd = txt.texts.map((i,ind) => <NewText key={ind} appStatus={appStatus} setAppStatus={setAppStatus} source={i.account.avatarUrl} pos={ind} userName={i.account.userName} userId={i.account.userId}
                                  type={i.textTypeName} status={i.status} title={i.title} text={i.text} setNewTitles={setNewTitles} setNewTexts={setNewTexts} allowedLanguages={i.allowedLanguages}
                                  rejRes={txt.allowedRejectionReasons} hasTitle={i.textTypeName === "Feed"} suspectedWords={i.suspectedWords}/>);
  }

  return(
    <div className="absolute top-[10vh] w-full">
      <div>
        <p className="inline mx-2">Product:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.product} onChange={(e)=>changeProduct(e)}>
          <option value="ALL">--All--</option>
          <option value="VX">VISIT-X</option>
          <option value="LA">LustAgenten</option>
          <option value="FEED">Feeds</option>
        </select>
        <p className="inline mx-2">Status:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.status} onChange={(e)=>changeStatus(e)}>
          <option value="new">NEW</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
        <p className="inline mx-2">Limit:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-12" value={appStatus.limit} onChange={(e)=>changeLimit(e)}>
          <option value="4">4</option>
          <option value="8">8</option>
          <option value="12">12</option>
        </select>
        <p className="inline mx-2">Model Id:</p>
        <input type="number" className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-24" value={modelIdPreview} onChange={(e)=>changeModelId(e)}></input>
        <button className="inline mx-1 text-zanBlue w-12" onClick={applySearch}>Apply</button>
        <button className="bg-lime-600 text-white rounded-full ml-1 px-2" onClick={()=>{saveTexts(txt.texts,appStatus);setDoRefresh(true)}}>
          Save Texts
        </button>
      </div>
      <p>{"Found total : " + txt.totalTexts + " Shown: " + txt.limit}</p>
      <div className="grid w-full grid-cols-2 gap-4">
        {gd}
      </div>
    </div>
  );
}

// Ein einzelner neuer Text
function NewText({appStatus,setAppStatus,source,pos,userName,userId,type,status,title,text,setNewTitles,setNewTexts,allowedLanguages,rejRes,hasTitle,suspectedWords}){
  const isInt = getCookie("internal")==="X";

  // Rejectionreason optionen
  let rr = rejRes.map((r,ind) => <option key={ind} value={r.id}>{r.label}</option>);

  // Auswahl wird nur angezeigt wenn Reject ausgewählt ist
  let sel = null;
  if(appStatus.rating[pos] !== "accepted"){
    sel = <>
            <p className="inline mx-1">
              Reason:
            </p>
            <select className="inline rounded-full bg-disabledGrey text-black p-1" value={appStatus.rating[pos]} onChange={(e)=>changeRating(e.target.value)}>
              {rr}
            </select>
          </>
  }

  function changeRating(val){
    let r = appStatus.rating;
    r[pos] = val;
    setAppStatus({
      ...appStatus,
      rating : r
    })
  }

  return(
    <div className="bg-white dark:bg-slate-700 rounded-3xl h-[220px] relative w-[49vw]">
      <div className="w-full h-8 border-b-2 px-4">
        <p className="p-1 font-bold text-gray-500">{type + " | " + status}</p>
      </div>
      <div className="grid grid-cols-5">
        <div className="col-span-1 col-start-1">
          <img src={source} alt="Profilbild" className="mx-auto"></img>
          {isInt ? <div className="text-center"><a className="font-semibold" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+userId} target="_blank" rel="noreferrer">{userName}</a></div>
          : <p className="text-center font-semibold">{userName}</p>}
        </div>
        <div className="col-span-4 col-start-2">
          <TextField title={title} hasTitle={hasTitle} text={text} pos={pos} setNewTitles={setNewTitles} setNewTexts={setNewTexts} allowedLanguages={allowedLanguages} suspectedWords={suspectedWords}/>
        </div>
        <div className="absolute bottom-1 left-[11vw]">
          <button onClick={(e)=>changeRating("accepted")} className={`${(appStatus.rating[pos] === "accepted" ?"text-white bg-lime-600":"bg-disabledGrey text-black")} rounded-full border-2 mx-1 px-1.5 py-0.5`}>
            Accepted
          </button>
          <button onClick={(e)=>changeRating(rejRes[0].id)} className={`${(appStatus.rating[pos] === "accepted" ?"bg-disabledGrey text-black":"text-white bg-zanRed")} rounded-full mx-1 px-1.5 py-0.5`}>
            Rejected
          </button>
          {sel}
        </div>
      </div>
    </div>
  );
}

function TextField({title,hasTitle,text,pos,setNewTitles,setNewTexts,allowedLanguages,suspectedWords}){
  // Per default wird der Deutsche Text angezeigt, falls nicht verfügbar der Englische
  const [descriptionLanguage,setDescriptionLanguage] = useState((text.find(i=>i.language==="DE")?.text !== "" || title.find(i=>i.language==="DE")?.text !== "") ? "DE" :
                                                                ((text.find(i=>i.language==="EN")?.text !== "" || title.find(i=>i.language==="EN")?.text !== "") ? "EN" : "ES"));
  const [isEditable,setIsEditable] = useState(false);
  
  // Changing the displayed language
  function changeLanguage(val){
    setDescriptionLanguage(val)
  }
  // Eventhandler for Title editing
  function onChangeTi(val){
    if(title.find(i=>i.language===descriptionLanguage)!==undefined){
      let d = title;
      d.find(i=>i.language===descriptionLanguage).text = val;
      setNewTitles(d,pos);
    }
  }
  // Eventhandler for Text editing
  function onChangeTe(val){
    if(text.find(i=>i.language===descriptionLanguage)!==undefined){
      let d = text;
      d.find(i=>i.language===descriptionLanguage).text = val;
      setNewTexts(d,pos);
    }
  }

  return(
    <div className="h-full grid grid-rows-6 justify-items-center relative">
      <TextLanguageButtons allowedLanguages={allowedLanguages} pos={pos} oc={changeLanguage} descriptionLanguage={descriptionLanguage} title={title} text={text} isEditable={isEditable} setIsEditable={setIsEditable}/>
      {hasTitle && <Title title={title} oc={onChangeTi} descriptionLanguage={descriptionLanguage} suspectedWords={suspectedWords} isEditable={isEditable}/>}
      <Textarea text={text} hasTitle={hasTitle} oc={onChangeTe} descriptionLanguage={descriptionLanguage} suspectedWords={suspectedWords} isEditable={isEditable}/>
    </div>
  );
}

function TextLanguageButtons({allowedLanguages,pos,oc,descriptionLanguage,title,text,isEditable,setIsEditable}){
  useEffect(()=>{
    if(!allowedLanguages.includes("DE")){
      document.getElementById("btnDE"+pos).disabled = true;
    }else if(title.find(i=>i.language==="DE").text.length===0 && text.find(i=>i.language==="DE").text.length===0){
      document.getElementById("btnDE"+pos).disabled = true;
    }
    if(!allowedLanguages.includes("EN")){
      document.getElementById("btnEN"+pos).disabled = true;
    }else if(title.find(i=>i.language==="EN").text.length===0 && text.find(i=>i.language==="EN").text.length===0){
      document.getElementById("btnEN"+pos).disabled = true;
    }
    if(!allowedLanguages.includes("ES")){
      document.getElementById("btnES"+pos).disabled = true;
    }else if(title.find(i=>i.language==="ES").text.length===0 && text.find(i=>i.language==="ES").text.length===0){
      document.getElementById("btnES"+pos).disabled = true;
    }
  });

  function changeEdit(){
    setIsEditable(!isEditable);
  }

  return(
    <div className="">
      <button id={"btnDE"+pos} className={`absolute top-2 left-[10px] ${(descriptionLanguage === 'DE') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("DE")}>DE</button>
      <button id={"btnEN"+pos} className={`absolute top-2 left-[50px] ${(descriptionLanguage === 'EN') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("EN")}>EN</button>
      <button id={"btnES"+pos} className={`absolute top-2 left-[90px] ${(descriptionLanguage === 'ES') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("ES")}>ES</button>
      <button className={`absolute top-2 left-[150px] ${(isEditable ? "text-zanBlue" : "text-gray-400")} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>changeEdit()}>Edit</button>
    </div>
  );
}

// Titel des Textes falls vorhanden
function Title({title,oc,descriptionLanguage,suspectedWords,isEditable}){
  // Textfield for edit mode, cannot be highlighted
  let edit = <input className="absolute left-5 top-7 h-6 w-[37vw] text-sm bg-transparent outline-none dark:bg-gray-500 border-b-2" type="text" value={title.find(t=>t.language===descriptionLanguage)?.text??""}
                onChange={(e) => oc(e.target.value)}></input>;
  
  let plain = <Highlighter 
      searchWords={suspectedWords}
      textToHighlight={title.find(t=>t.language===descriptionLanguage)?.text??""}
      className={"absolute left-5 top-7 h-6 w-[37vw] text-sm bg-transparent outline-none dark:bg-gray-500 border-b-2 overflow-y-scroll"}
    />

  return(
    <div className="">
      {isEditable ? edit : plain}
    </div>
  );
}

// Haupttext des Textes
function Textarea({text,hasTitle,oc,descriptionLanguage,suspectedWords,isEditable}){  
  // Textfield for edit mode, cannot be highlighted
  let edit = <textarea className={`absolute left-5 ${hasTitle ? "top-14 h-[4vh]" : "top-8 h-[8vh]"} w-[37vw] text-sm bg-transparent outline-none dark:bg-gray-500 resize-none`} value={text.find(t=>t.language===descriptionLanguage)?.text??""}
              onChange={(e) => oc(e.target.value)}></textarea>;
  
  let plain = <Highlighter 
      searchWords={suspectedWords}
      textToHighlight={text.find(t=>t.language===descriptionLanguage)?.text??""}
      className={`absolute left-5 ${hasTitle ? "top-14 h-[4vh]" : "top-8 h-[8vh]"} w-[37vw] text-sm bg-transparent outline-none dark:bg-gray-500 resize-none`}
    />
  return(
    <div className="">
      {isEditable ? edit : plain}
    </div>
  );
}