import React, {useState} from "react";
import NavBar from "./NavBar";

export default function HelpPageApp(){

  const ratingCat = 10;

  // eslint-disable-next-line
  const [secondaryCategory,setSecondaryCategory] = useState("AGE_RESTR");


  let page = null;
  switch(sessionStorage.getItem("sc")){
    case "AGE_RESTR": page = <Jugendschutz />;
          break;
    case "CON_RESTR": page = <ContentRestrictions />;
          break;
    case "TOOL_HELP": page = <ContentToolHelp />
          break;
    default :
  }

  return(
    <div className="dark:bg-gray-800 dark:text-white transition-all min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      <div className="absolute top-[11vh]">
        {page}
      </div>
    </div>
  );
}

function Jugendschutz(){
  return(
    <div className="mx-20 space-y-4 max-w-[1000px] bg-white dark:bg-gray-700 p-4 rounded-3xl">
      <h1 className="text-3xl font-bold">
        Aushilfen-Freigaberichtlinien: Jugendschutz
      </h1>
      <p>
        Als Betreiber eines Internet-Portals sind wir einerseits an gesetzliche Richtlinien zur Einhaltung des Jugendmedienschutzes gebunden und prüfen andererseits im Rahmen der „regulierten Selbstregulierung“ unsere Inhalte auf Content Restrictions.
        Hier sind wir bemüht, die Interessen des Gesetzgebers und der Darsteller gleichermaßen zu vertreten.
        Neben der puren Einstufung nach F12 / F16 / F18 gilt es auch, Content zu beurteilen, der ggfls. gegen das Gesetz bzw. unsere eigenen Richtlinien verstößt (Content Restrictions).
      </p>
      <h3 className="text-xl font-bold">
        Allgemein:
      </h3>
      <ul>
        <li>Wir wollen immer sehr gute und aussagekräftige Inhalte auf unserer Plattform präsentieren.</li>
        <li>Die Inhalte, die vom VX-Team geprüft werden, sind auf Visit-X.net und Partnerseiten zu sehen und sollen besonders das Produkt VISIT-X repräsentieren und - im Rahmen des Möglichen - VISIT-X und seinen Partnern Rechtssicherheit garantieren.</li>
        <li>Die folgende Aufstellung gilt für Video- als auch Bilder-Content und Texte gleichermaßen.</li>
      </ul>
      <h3 className="text-xl font-bold">
        Ansprechpartner
      </h3>
      <p>
        Modelanfragen: ModelService Team
      </p>
      <p>
        Jugendschutzanfragen: Christof Mendner
      </p>
      <h2 className="text-2xl font-bold">
        Super Soft - Flirtcore / F12
      </h2>
      <p>
        F12 Inhalte beinhalten Content von Darstellern, die komplett angezogen sind und keinerlei sexuellen Bezug in der Bildsprache haben.
      </p>
      <ul className="text-green-600 list-disc list-outside mx-4">
        <li>Darsteller ist/sind komplett mit Alltagskleidung angezogen</li>
        <li>Bikini ist nur an Orten erlaubt, an denen man Bikinis für gewöhnlich trägt (am Strand, draußen im Garten). Das gleiche gilt für Nachthemden (Schlafzimmer), gewickeltes Handtuch (Bad/Sauna)</li>
        <li>Fokus auf Hände, Füße, Lippen ist ok</li>
        <li>BH-Träger / Slip sind ok, wenn der BH / Slip nur minimal aus dem Shirt / Hose hervorschaut</li>
      </ul>
      <ul className="text-red-500 list-disc list-outside mx-4">
        <li>Aufreizende Kleidung ist nicht ok</li>
        <li>Sexuelle Posen sind nicht ok (breitbeinig mit Hand zwischen den Beinen, Pärchen bei angetäuschtem Geschlechtsverkehr)</li>
        <li>Toys dürfen nicht im Bild zu sehen sein</li>
        <li>Fokus auf Hintern, Brüste ist nicht ok</li>
        <li>Unterwäsche, Dessous, halterlose Strümpfe sind nicht ok</li>
        <li>Durchsichtige Kleidung, bei der man Brust oder Unterwäsche durchsehen kann, ist nicht ok</li>
      </ul>
      <h2 className="text-2xl font-bold">
        Softcore / F16
      </h2>
      <p>
        F16 Inhalte beinhalten Content von Darstellern, die höchstens oben ohne zu sehen sind bzw. so abgebildet wurde, dass keine primären Geschlechtsteile zu sehen sind.
      </p>
      <ul className="text-green-600 list-disc list-outside mx-4">
        <li>Darsteller sind idealerweise höchstens oben ohne zu sehen (gilt auch für Männer)</li>
        <li>Toys, die nicht im sexuellen Kontext / Gebrauch sind (z.B. nur in der Hand gehalten werden oder nur zu sehen sind) sind ok</li>
        <li>Andeutungen von sexuellen-Handlungen sind zulässig, wenn sie nicht zu offensiv sind</li>
        <li>Anfassen von Brust / Busen ist ok</li>
        <li>Anfassen des Genitalbereichs ist nur zulässig, wenn alle Finger zu 100 % zu sehen sind bzw. auf Videos nicht „streichelnd“ verwendet werden</li>
        <li>Brustwarzen dürfen zu sehen sein (auch lecken/anfassen)</li>
        <li>Fetisch/SM darf angedeutet werden</li>
        <li>Andeutungen von sexuellen Handlungen so lange bei Couples noch bei beiden ein Kleidungsstück zu sehen ist (z. B. Frau sitzt bei Mann auf Schoß in Unterwäsche und er hat die Hand auf ihrer Brust)</li>
        <li>Kritische Stellen komplett abdeckende Wischer / Balken sind ok</li>
      </ul>
      <ul className="text-red-500 list-disc list-outside mx-4">
        <li>Kritische Stellen lediglich blurren oder verpixeln ist nicht ok</li>
        <li>Toys die in Verwendung sind (in der Hand / wischen den Füßen gehalten werden) dürfen keine realistischen Plastiken von z.B. Penis / Vagina / Anus darstellen. Phallus artige Gegenstände dürfen nicht in Aktion sein (Bananen-Blowjob etc.)</li>
        <li>Wenn Darsteller komplett nackt sind, darf auf keinen Fall ein primäres Geschlechtsteil zu sehen sein. Auch nicht teilweise oder im Ansatz</li>
        <li>Natursekt (auch nasse Jeans), Kaviar, Kotze ist nicht ok</li>
        <li>Sperma auf Gesicht oder Brust ist nicht zulässig</li>
      </ul>
      <h2 className="text-2xl font-bold">
        Hardcore / F18
      </h2>
      <p>
        F18 Inhalte beinhalten Content von Darstellern, deren primären Geschlechtsteile (Penis, Vagina, Anus) oder auch nur Teile davon zu sehen sind und/oder die sich eindeutig sexuell betätigen.
      </p>
      <ul className="text-green-600 list-disc list-outside mx-4">
        <li>Nackte Darsteller bei denen alles zu sehen ist</li>
        <li>Darsteller in sexueller Interaktion (Petting / Blow-,Rim-Lickjob / Geschlechtsverkehr / Gruppensex etc.))</li>
        <li>Offensive, extrem versaute und eindeutig sexuelle Abbildungen bzw. Handlungen, auch wenn die Darsteller teilweise angezogen sind</li>
        <li>Sperma im Gesicht oder auf dem Körper</li>
        <li>Toys im eindeutig sexuellen Gebrauch</li>
        <li>Brustwarzen ziehen / einklemmen</li>
        <li>Durchsichtige oder zu knappe Kleidung, bei denen primäre Geschlechtsteile (oder Teile davon) zu sehen sind</li>
        <li>Uneindeutige Bereiche, bei denen ein Schatten auch als Schamlippe gedeutet werden könnte, bzw. ein Anus nur unzureichend von einem String-Tanga verdeckt wird</li>
        <li>Natursekt, ist generell F18</li>
        <li>Extremer bzw. ausgelebter Fetisch (Wachs, Nadeln, abschnüren von Körperteilen etc.)</li>
      </ul>
      <ul className="text-red-500 list-disc list-outside mx-4">
        <li>Kaviar, Kotze sind verboten</li>
      </ul>
      <br></br>
      <p>
        Stand: 19.07.2022
      </p>
    </div>
  );
}

function ContentRestrictions(){
  return(
    <div className="mx-20 space-y-8 max-w-[1000px] bg-white dark:bg-gray-700 p-4 rounded-3xl">
      <h1 className="text-3xl font-bold">
        Freigaberichtlinien: Content Restrictions
      </h1>
      <p>
        Die Content-Restrictions beziehen sich nicht nur auf die eigentlichen Video- und Bild-Inhalte sondern auch auf die Beschreibungstexte und Tags.
        Die im Folgenden aufgeführten Punkte sind ausnahmslos als NoGos einzustufen.
      </p>
      <ol className="list-decimal mx-4">
        <li>
          <p className="font-bold">Scheinminderjährigkeit:</p>
          <p>Generell verboten sind:</p>
          <ul className="text-red-500 list-disc list-outside mx-8">
            <li>Kuscheltiere (im Vordergrund oder in Aktion)</li>
            <li>Schuluniform/Schulranzen</li>
            <li>Darsteller/-in sieht zu jung aus</li>
          </ul>
          <p>Ansonsten müssen mind. 2 Kriterien zutreffen, damit Bilder gegen die Hausordnung verstoßen. Die Kombination folgender Komponenten ist verboten:</p>
          <ul className="text-red-500 list-disc list-outside mx-8">
            <li>Pferdezöpfe</li>
            <li>Lolli/Lutscher</li>
            <li>Pferdeposter an Wand</li>
            <li>kindliche Pose/Kleidung</li>
            <li>Kuscheltiere (auch im Hintergrund)</li>
          </ul>
        </li>
        <li>
          <p className="font-bold">Kinder:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Kinder im Bild oder im Video {<p className="text-green-600">(wird ein Video draußen gedreht und ist ein Kind nur zu hören, nicht zu sehen, zählt es als Umweltgeräusch und wird akzeptiert).</p>}</li>
            <li>Kinder-Spielzeug usw.</li>
            <li>Alles, worauf man schließen kann, dass ein Kind mit im Haushalt wohnt (Schulrucksack)</li>
            <li>Familienfotos, wenn sie im Fokus sind oder Kinder zu erkennen sind</li>
          </ul>
        </li>
        <li>
          <p className="font-bold">Tiere / Tierpornographie / Zoophilie:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Tiere im Bild oder Video {<p className="text-green-600">(wird ein Video draußen gedreht und ist ein Tier nur zu hören, nicht zu sehen, zählt es als Umweltgeräusch und wird akzeptiert).</p>}</li>
            <li className="text-green-600">Tierzubehör wie Katzenkratzbäume, Fressnapf sind bei Petplay erlaubt</li>
          </ul>
        </li>
        <li>
          <p className="font-bold">Politischer Hintergrund:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Flaggen</li>
            <li>Rechts-/Linksradikal (Springerstiefel mit weißen Schnürsenkeln)</li>
            <li>Parteien</li>
            <li>Waffen</li>
          </ul>
        </li>
        <li>
          <p className="font-bold">Extremer Fetisch:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Nekrophilie / Leiche / Tod</li>
            <li>Zu extremer SM; wenn Darstellerin im Video nur schreit, weint oder zittert</li>
            <li>Blut / Menstruationsblut</li>
            <li>Andeutungen, sich aufzuhängen (Strick um den Hals)</li>
            <li>Spielarten wie Asphyx / Erstickung / Atemreduktion</li>
            <li>Kaviar, Kotze</li>
          </ul>
        </li>
        <li>
          <p className="font-bold">Nichteinvernehmliche Tätigkeiten:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Offensive Gewalt gegen Darsteller</li>
            <li>Sex gegen den Willen eines Darstellers</li>
            <li>Rape / Vergewaltigung / Missbrauch / missbrauchen</li>
            <li>Alkohol / Chloroform / Drogen etc.</li>
            <li>Handlungsunfähig (ohnmächtig geworden / sediert / bewusstlos / schlafend / besoffen / betrunken / betäubt / bekifft / hypnotisiert etc.)</li>
            <li>In diesem Zusammenhang auch untersagt der Gebrauch / die Nennung von Aufputschmitteln / Arzneimitteln / Betäubungsmitteln / Drogen / Poppers, Intox etc.</li>
          </ul>
          <p>Besonders bei den Texten ist darauf zu achten, dass das Wort Alkohol, betrunken, besoffen nicht verwendet wird.</p>
        </li>
        <li>
          <p className="font-bold">Sex gegen Geld und AO:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Keine Werbung für bezahlten Sex / Prostitution</li>
            <li>Mit Beschreibung und Inhalt darf generell keine Werbung für bezahlten Sex gemacht werden. {<p className="text-green-600">Das Andeuten von privaten Treffen oder Ausschreiben von Userdrehs durch das Model ist aber gestattet (solange keine finanziellen Interessen angedeutet werden).</p>}</li>
            <li>AO und Geld in Beschreibung und Inhalt ist OK, wenn Video als „Rollenspiel“ oder „scripted Reality“ zu erkennen ist. Das Ganze darf in keinem Fall nach echter Prostitution aussehen.</li>
            <li>„AO“ und „ohne Gummi“ kann im Titel erscheinen</li>
            <li>„AO“ als Keyword (Verlinkung) und im Beschreibungstext ist erlaubt</li>
          </ul>
        </li>
        <li>
          <p className="font-bold">Diskriminierende Aussagen / Darstellungen hinsichtlich:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Rasse</li>
            <li>Geschlecht</li>
            <li>Religion</li>
            <li>Nationalität</li>
            <li>Behinderung</li>
            <li>sexueller Neigung</li>
            <li>Alter</li>
          </ul>
          <p>Anmerkung: Eine Ausnahme stellt hier der Fetisch-Bereich dar da dort Erniedrigung zum "guten Ton" gehört.</p>
        </li>
        <li>
          <p className="font-bold">Tags, Beschreibung von Videos und Bilderalben:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Minderjährigkeit: Girly, Lolita, Schulmädchen, Schüler/in, Lehrer</li>
            <li>In Beschreibungen können diese Schlagworte in Studentin, Professor, usw. geändert werden, wenn im Video selbst das dargestellte Rollenspiel nicht zu eindeutig auf Teen oder Schulmädchen abzielt.</li>
          </ul>
          <p>Teen/ie/y/ager usw kann als Wort/Bezeichnung nur verwendet werden, wenn das Model NICHT wie ein Teen aussieht oder zurecht gemacht ist. Auch die Umgebung des Models darf nicht auf ein Teen hinweisen (Schulranzen, Kinderzimmer, Schuluniform, Zöpfe, usw.)</p>
        </li>
        <li>
          <p className="font-bold">Hinweise auf Verwandtschaft:</p>
          <ul className="text-red-500 list-disc list-outside mx-4">
            <li>Sex mit Verwandten ersten Grades gilt als Inzest und ist generell verboten!</li>
            <li>Tags oder Beschreibungen mit solchen Verwandtschaftsverhältnissen, wie Bruder/Schwester, Tochter/Vater werden abgeändert, wenn im Video nicht eindeutig hervorgeht, dass es sich nicht um Inzest handelt. Ansonsten wird das Video abgelehnt!</li>
            <li className="text-green-600">OK sind dagegen: Stiefschwester/Stiefbruder, da keine Blutsverwandtschaft besteht. Im Video sollte jedoch hervorgehen, dass es wirklich nur um Stiefgeschwister handelt. Ansonsten wird das Video abgelehnt!</li>
          </ul>
          <p>Um zukünftig False-Positive-Ratings vorzubeugen, sollten z.B. Texte wie “Sex mit dem Mann meiner Schwester” in “Sex mit meinem Schwager” umbenannt werden.</p>
        </li>
      </ol>
      <br></br>
      <p>
        Stand: 19.07.2022
      </p>
    </div>
  );
}

function ContentToolHelp(){
  return(
  <div className="mx-20 space-y-4 max-w-[1000px] bg-white dark:bg-gray-700 p-4 rounded-3xl">
    <h1 className="text-3xl font-bold">
        Content Rating Tool Hilfe
      </h1>
      <p>
        Hier sind einige nützliche Tipps zum Rating Tool zu finden
      </p>
      <h2 className="text-xl font-bold">Allgemeine Tipps:</h2>
      <ul className="list-disc list-outside mx-4">
        <li>
          <p>Das Mausrad ist in Textfeldern sehr empfindlich, daher empiehlt es sich die Pfeiltasten zu drücken oder die Scrollbar zu ziehen</p>
        </li>
      </ul>
      <h2 className="text-xl font-bold">PhotoRating</h2>
      <p>
        Bei der Mehrfachauswahl der Bilder kann mit einem Doppelklick auf ein nicht-/ausgewähltes alles an-/abgewählt werden
      </p>
      <h3 className="text-lg font-bold">
        Hotkeys:
      </h3>
      <p>
        Die Hotkeys funktionieren nicht wenn ein Textfeld ausgewählt ist. (Schwarzer Rand um das Element)
      </p>
      <ul className="list-disc list-outside mx-4">
        <li>
          <p>1: FSK 18 auswählen</p>
        </li>
        <li>
          <p>2: FSK 16 auswählen</p>
        </li>
        <li>
          <p>3: FSK 12 auswählen</p>
        </li>
        <li>
          <p>Leertaste: Ausgeählte Bilder raten</p>
        </li>
      </ul>
      <h2 className="text-xl font-bold">VideoRating Hotkeys:</h2>
      <p>
        Die Hotkeys funktionieren nur im Bildvorschaumodus (Großes Vorschaubild)
      </p>
      <ul className="list-disc list-outside mx-4">
        <li>
          <p>1: FSK 18 auswählen</p>
        </li>
        <li>
          <p>2: FSK 16 auswählen</p>
        </li>
        <li>
          <p>Pfeiltaste Links: Ein Bild zurück</p>
        </li>
        <li>
          <p>Pfeiltaste Rechts: Ein Bild nach vorne</p>
        </li>
        <li>
          <p>Escape: Den Vorschaumodus Beenden</p>
        </li>
      </ul>
  </div>
  );
}