import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { forbidFsk16Chat, getFirstCat, getNextFsk16Chats, getCookie } from "../scripts/xhrf";

export default function Fsk16ChatReviewApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 8;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    page : 0,
    limit : 20,
    from : "",
    to : "",
    bigViewMode : false,
    bigViewIndex : 0,
    bigViewImage : 0
  });

  // in fsk16chats werden die Chats gespeichert
  const [fsk16Chats,setFsk16Chats] = useState({});
  
   // Status zum anzeigen der Ladeanimation
   const [loading,setLoading] = useState(true);

   // Status zum manuellen refresh
   const [doRefresh,setDoRefresh] = useState(false);

  useEffect(()=>{
    async function getData(){
      let lt = appStatus.limit;
      let pg = appStatus.page;
      let fr = appStatus.from;
      let to = appStatus.to;
      let c = await getNextFsk16Chats(lt,pg,fr,to);
      if(c.errors !== undefined){
        alert("Fehler: " + c.errors[0].message);
      }
      setFsk16Chats(c.data.fsk16ChatsReview);
      setAppStatus({
        page : pg,
        limit : lt,
        userForbid : c.data.fsk16ChatsReview.items.map((a)=>a.isAlreadyForbidden),
        from : c.data.fsk16ChatsReview.fromDate.replace("+00:00","").replace("T"," "),
        to : c.data.fsk16ChatsReview.toDate.replace("+00:00","").replace("T"," "),
        bigViewMode : false,
        bigViewIndex : 0,
        bigViewImage : 0
      })
      console.log(c);
      setLoading(false);
      setDoRefresh(false);
    }
    setLoading(true);
    getData();
  },[secondaryCategory,doRefresh,appStatus.limit,appStatus.page,appStatus.from,appStatus.to]);

  let contentF = <ChatReview
                  appStatus={appStatus}
                  setAppStatus={setAppStatus}
                  fsk16Chats={fsk16Chats}
                 />;

  if(loading){
    contentF = null;
  }
      

  return(
    <div className="dark:bg-gray-800 dark:text-white min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -12 ? contentF : <p className="absolute top-[10vh] left-2">{"No Models to verify"}</p>}
    </div>
  );
}


// Hauptkomponente für die Kontrolle
function ChatReview({appStatus,setAppStatus,fsk16Chats}){

  const [fromDatePreview,setFromDatePreview] = useState(appStatus.from);
  const [toDatePreview,setToDatePreview] = useState(appStatus.to);

  var maxPages = Math.ceil(fsk16Chats.totalItems/Number(appStatus.limit));

  // Verbietet einem Model die Fsk16Chat Funktion
  function forbidChat(modelId,chatId,index){
    let a = appStatus.userForbid;
    a[index] = true;
    setAppStatus({
      ...appStatus,
      userForbid : a
    });
    forbidFsk16Chat(modelId,chatId);
  }

  function changeLimit(e){
    setAppStatus({
      ...appStatus,
      limit : e.target.value
    });
  }
  function changeFromDate(e){
    setFromDatePreview(e.target.value);
  }
  function changeToDate(e){
    setToDatePreview(e.target.value);
  }
  function applyDates(){
    setAppStatus({
      ...appStatus,
      from : fromDatePreview,
      to : toDatePreview
    });
  }

  function firstPage(){
    console.log(appStatus);
    setAppStatus({
      ...appStatus,
      page : 0
    });
  }
  function prevPage(){
    if(appStatus.page > 0){
      setAppStatus({
        ...appStatus,
        page : appStatus.page-1
      });
    }
  }
  function nextPage(){
    if(appStatus.page+1<maxPages){
      setAppStatus({
        ...appStatus,
        page : appStatus.page+1
      });
    }
  }
  function lastPage(){
    setAppStatus({
      ...appStatus,
      page : maxPages-1
    });
  }

  let chats = fsk16Chats.items.map((c,index) => <Fsk16Chat key={index} chat={c} buttonDisabled={appStatus.userForbid[index]} forbidChat={forbidChat} index={index} appStatus={appStatus} setAppStatus={setAppStatus}/>);

  let controlbar =  <div>
                      <p className="inline mx-2">{`Found: ${fsk16Chats.totalItems??0} chats`}</p>
                      <p className="inline mx-2">{`Shown: ${fsk16Chats.items?.length??0} feedbacks`}</p>
                      <p className="inline mx-2">{`Page: ${Number(appStatus.page)+1} of ${maxPages}`}</p>
                      <button className="inline mx-1" onClick={()=>firstPage()}>{"<<First"}</button>
                      <button className="inline mx-1" onClick={()=>prevPage()}>{"<Previous"}</button>
                      <button className="inline mx-1" onClick={()=>nextPage()}>{"Next>"}</button>
                      <button className="inline mx-1" onClick={()=>lastPage()}>{"Last>>"}</button>
                    </div>


  // Closes the Overview
  function closeOverview(){
    setAppStatus({
      ...appStatus,
      bigViewMode : false
    });
  }
  const prevImage = () => {
    if(appStatus.bigViewImage > 0){
      setAppStatus({
        ...appStatus,
        bigViewImage : appStatus.bigViewImage - 1
      });
    }
  }
  // Switches to next picture in overlay mode if possible
  const nextImage = () => {
    if(appStatus.bigViewImage+1 < fsk16Chats.items[appStatus.bigViewIndex].snapshots.length){
      setAppStatus({
        ...appStatus,
        bigViewImage : appStatus.bigViewImage + 1
      });
    }
  }
  // Overlay showing a single Preview Picture in bigger Size
  let pictureOverlay = null;
  if(appStatus.bigViewMode){
    pictureOverlay = <div className="overlay fixed left-[2.1vw] top-[0vh] h-[90vh] w-[96vw] bg-black bg-opacity-90 rounded-3xl">
      <p className="overlay text-white text-5xl absolute left-5 top-5">{(appStatus.bigViewImage+1)+"/"+fsk16Chats.items[appStatus.bigViewIndex].snapshots.length}</p>
      <button className="overlay text-gray-400 text-5xl font-bold absolute right-[1vw] top-[2vh] rounded-full h-14 w-14" onClick={closeOverview}>X</button>
      <a className="overlay w-[80vw] h-[75vh] top-[1vh] left-[7.5vw] absolute" href={fsk16Chats.items[appStatus.bigViewIndex].snapshots[appStatus.bigViewImage]} target="_blank" rel="noreferrer">
        <img className="overlay object-contain w-[80vw] h-[75vh]" src={fsk16Chats.items[appStatus.bigViewIndex].snapshots[appStatus.bigViewImage]} alt="Vorschaubild"></img>
      </a>
      <button className="overlay bg-gray-600 rounded-full absolute top-[40vh] left-[0.8vw] h-[70px] w-[70px]" onClick={prevImage}>
        <p className="overlay text-6xl text-white mx-auto pb-1">{"<"}</p>
      </button>
      <button className="overlay bg-gray-600 rounded-full absolute top-[40vh] right-[0.8vw] h-[70px] w-[70px]" onClick={nextImage}>
        <p className="overlay text-6xl text-white mx-auto pb-1">{">"}</p>
      </button>
    </div>;}

  // Attaches the Eventlistener for BigPreviewPicture
  useEffect(()=>{
    function changePic(e){
      if(appStatus.bigViewMode){
        if(e.repeat){
          //return;
        }
        if(e.key === "ArrowLeft"){
          e.preventDefault();
          prevImage();
        }else if(e.key === "ArrowRight"){
          e.preventDefault();
          nextImage();
        }else if(e.key === "Escape"){
          closeOverview();
        }
      }
    }
    window.addEventListener("keydown",changePic);
    return () => {window.removeEventListener("keydown",changePic)}
  });

  return(
    <div className="absolute top-[7vh] w-full">
      <div>
        <p className="inline mx-2">Show:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-12" value={appStatus.limit} onChange={(e)=>changeLimit(e)}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
        <p className="inline mx-2">From:</p>
        <input className="w-56 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={fromDatePreview} onChange={(e)=>changeFromDate(e)}></input>
        <p className="inline mx-2">To:</p>
        <input className="w-56 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={toDatePreview} onChange={(e)=>changeToDate(e)}></input>
        <button className="text-zanBlue mx-2" onClick={applyDates}>Apply</button>
      </div>
      {controlbar}
      {chats}
      {pictureOverlay}
      <div className="h-4"></div>
      {controlbar}
      <div className="h-4"></div>
    </div>
  );
}

// Komponente die einen einzelnen Chat darstellt
function Fsk16Chat({chat,buttonDisabled,forbidChat,index,appStatus,setAppStatus}){
  let snaps = chat.snapshots.map((s,index)=><button onClick={()=>openBigView(index)} key={index}><img alt="" src={s} className="inline h-24"></img></button>);
  
  function openBigView(ind){
    setAppStatus({
      ...appStatus,
      bigViewMode : true,
      bigViewIndex : index,
      bigViewImage : ind
    });
  }
  
  return(
    <div>
      <div className="bg-white rounded 2xl w-full my-1 relative">
        <p className="inline mx-1">FSK-16</p>
        <p className="inline mx-1">ChatID:</p>
        {getCookie('internal') === "X" ? <a className="inline mr-2 font-bold" href={"https://vxadmin.ops.xxx/CONTROL/851d5b123758f017aeefeacce2a7ad8811929217/sector=001//v7main/chat.php?chatid="+chat.chatId} target="_blank" rel="noreferrer">{chat.chatId}</a> :
        <p className="inline mr-2 font-bold">{chat.chatId}</p>}
        <p className="inline mx-1">Host:</p>
        {getCookie('internal') === "X" ? <a className="inline mr-2 font-bold" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+chat.modelId} target="_blank" rel="noreferrer">{chat.modelName}</a> :
        <p className="inline mr-2 font-bold">{chat.modelName}</p>}
        <p className="inline mx-1">Guest:</p>
        <p className="inline mr-2 font-bold">{chat.guestName}</p>
        <p className="inline mx-1">Start:</p>
        <p className="inline mr-2 font-bold">{chat.startDate.substring(0,10) + " " + chat.startDate.substring(11,16)}</p>
        <p className="inline mx-1">Length:</p>
        <p className="inline mr-2 font-bold">{new Date(chat.duration*1000).toLocaleTimeString('de',{ timeZone: 'UTC' })}</p>
        {buttonDisabled ? <p className="inline absolute left-[900px]">Already Forbidden</p> : 
        <button className="inline absolute left-[900px] ml-1 px-2 bg-red-500 disabled:bg-transparent disabled:text-transparent text-white rounded-full" onClick={()=>forbidChat(chat.modelId,chat.chatId,index)} disabled={buttonDisabled}>
          Forbid soft chat
        </button>}
        
      </div>
      {snaps}
      <div className="h-4 w-full"></div>
    </div>
  );
}