import { useEffect, useState } from "react";
//import { getSupportEmail } from "../scripts/config";
import { filterJobs, getCookie, getJobs, loginToLustagenten, removeCookie, updateJobs } from "../scripts/xhrf";

export default function NavBar({ ratingCat, setSecondaryCategory }) {
  // Falls kein Token-Cookie vorhanden ist wird man zum Login weitergeleitet und ältere Interner Mitarbeiter-Cookies entfernt falls vorhanden
  if (getCookie("token") === null) {
    removeCookie("internal");
    window.location.href = "/login";
  }

  const [globalJobQueue, setGlobalJobQueue] = useState(getJobs());
  let isFiltered = sessionStorage.getItem("slot") !== "-1" && sessionStorage.getItem("slot") !== null;

  // Sollte keine Unterkategorie angegeben sein, so wird nach einer Freien Unterkategorie gesucht.
  // Die Ratingcat (Rating Category) der Hauptkategorien entscheidet in welchem Bereich gesucht werden soll
  if (sessionStorage.getItem("sc") === null) {
    switch (ratingCat) {
      case 1:
        selectNextSecondaryCategory("PhotoCheck");
        break;
      case 2:
        selectNextSecondaryCategory("PhotoRecheck");
        break;
      case 3:
        selectNextSecondaryCategory("VideoCheck");
        break;
      case 4:
        selectNextSecondaryCategory("VideoRecheck");
        break;
      case 5:
        selectNextSecondaryCategory("TEXT");
        break;
      case 9:
        selectNextSecondaryCategory("HELP");
        break;
      default:
    }
  }

  // Diese Funktion wird ausgeführt wenn keine Unterkategorie angegeben ist.
  // Dies passiert z.B. auch, wenn ein Button für eine Hauptkategorie ausgewählt wird
  function selectNextSecondaryCategory(loc) {
    sessionStorage.removeItem("previous");
    sessionStorage.removeItem("skipIds");
    sessionStorage.removeItem("slot");
    switch (loc) {
      case "PhotoCheck":
        for (let e in globalJobQueue.photoRatingCounts) {
          if (globalJobQueue.photoRatingCounts[e].counts > 0) {
            sessionStorage.setItem("sc", globalJobQueue.photoRatingCounts[e].type);
            break;
          }
        }
        sessionStorage.setItem("mode", "check");
        break;
      case "PhotoRecheck":
        for (let e in globalJobQueue.photoReratingCounts) {
          if (globalJobQueue.photoReratingCounts[e].counts > 0) {
            sessionStorage.setItem("sc", globalJobQueue.photoReratingCounts[e].type);
            break;
          }
        }
        sessionStorage.setItem("mode", "recheck");
        break;
      case "VideoCheck":
        for (let e in globalJobQueue.videoRatingCounts) {
          if (globalJobQueue.videoRatingCounts[e].counts > 0) {
            sessionStorage.setItem("sc", globalJobQueue.videoRatingCounts[e].type);
            break;
          }
        }
        sessionStorage.setItem("mode", "check");
        break;
      case "VideoRecheck":
        for (let e in globalJobQueue.videoReratingCounts) {
          if (globalJobQueue.videoReratingCounts[e].counts > 0) {
            sessionStorage.setItem("sc", globalJobQueue.videoReratingCounts[e].type);
            break;
          }
        }
        sessionStorage.setItem("mode", "recheck");
        break;
      case "TEXT":
        let conc = globalJobQueue.commentRatingCounts.concat(
          globalJobQueue.textRatingCounts.find((i) => i.type === "VX")
        );
        for (let e in conc) {
          if (conc[e].counts > 0) {
            //sessionStorage.setItem('sc',conc[e].type);
            sessionStorage.setItem("sc", "FEED");
            break;
          }
        }
        sessionStorage.setItem("mode", "check");
        break;
      case "HELP":
        sessionStorage.setItem("sc", "AGE_RESTR");
        break;
      default:
        sessionStorage.setItem("sc", -1);
        sessionStorage.setItem("page", null);
    }
  }

  // Diese Funktion wird aufgerufen wenn ein Button einer Unterkategorie ausgewählt wird
  // setSecondaryCategory triggert ein Repload der Seite, wenn die Unterkategorie gewechselt wird
  // Die Unterkategorie wird zwischenzeitlich im sessionstorage gespeichert
  function selectSecondaryCategory(val) {
    if (ratingCat === 1 && globalJobQueue.photoRatingCounts.find((i) => i.type === val).counts !== 0) {
      //triggeredAlarm();
    } else if (ratingCat === 2) {
      //triggeredAlarm();
    } else if (ratingCat === 3 && globalJobQueue.videoRatingCounts.find((i) => i.type === val).counts !== 0) {
    }
    setSecondaryCategory(val);
    if (sessionStorage.getItem("sc") !== val) {
      sessionStorage.removeItem("skipIds");
    }
    sessionStorage.setItem("sc", val);
    sessionStorage.removeItem("previous");
  }

  /*
  const [isAlerted,setAlerted] = useState(false);
  let alertMessage = <div className="absolute w-[20vw] h-10 left-[40vw] top-5 bg-white border-2 border-black rounded-full shadow-lg">
                      <p className="font-bold text-2xl text-center dark:text-black">{"Changed to "+(globalJobQueue.photoRatingCounts.find(i=>i.type===sessionStorage.getItem('sc'))?.label)}</p>
                     </div>;
  function triggeredAlarm(){
    setAlerted(true);
    setTimeout(()=>{setAlerted(false)},500);
  }
  */

  // Hier wird der Polling Job für die Updates der Jobliste gescheduled. Der Timer wurde explizit so kurz gewünscht.
  // Da nicht mehr als 5-10 Personen das Tool nutzen werden muss man sich um Netzwerkauslastung keine zu großen sorgen machen.
  useEffect(() => {
    async function updater() {
      await updateJobs();
      let jobs = null;
      if (isFiltered) {
        jobs = await filterJobs();
        console.log(jobs);
      } else {
        jobs = JSON.parse(localStorage.getItem("jobs"));
      }
      setGlobalJobQueue(jobs);
    }
    updater();
    let up = setInterval(() => {
      updater();
    }, 60_000);
    return () => clearInterval(up);
  }, [setGlobalJobQueue, isFiltered]);

  // In ItemCats werden die Unterkategorien für die jeweiligen Hauptkategorien gespeichert. Je nachdem welche Hauptkategorie
  // ausgewählt ist, stellt Itemcats die entsprechenenden Unterkategorien dar falls vorhanden
  var itemCats = null;
  switch (ratingCat) {
    case 1:
      itemCats = <PictureCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break;
    case 2:
      itemCats = <PictureRecheckCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break;
    case 3:
      itemCats = <VideoCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break;
    case 4:
      itemCats = <VideoRecheckCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break;
    case 5:
      itemCats = <TextCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break;
    case 6:
      itemCats = <TextCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break;
    case 14:
      itemCats = <TextCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break; // Powermailing
    case 15:
      itemCats = <TextCategories oc={selectSecondaryCategory} globalJobQueue={globalJobQueue} />;
      break; // Badwords
    case 10:
      itemCats = <HelpCategories oc={selectSecondaryCategory} />;
      break;
    default:
  }

  /*
  const [reportScreen,setReportScreen] = useState(false);
  function makeReport(){
    setReportScreen(!reportScreen);
  }
  */

  return (
    <div className="absolute top-[0.5vh] left-[1vw] h-[6vh] w-[98vw]">
      <Menubar
        ratingCat={ratingCat}
        globalJobQueue={globalJobQueue}
        selectNextSecondaryCategory={selectNextSecondaryCategory}
        //makeReport={makeReport}
      />
      <div className="absolute top-[5vh]">{itemCats}</div>
      {/*isAlerted && alertMessage*/}
      {/*reportScreen && <ReportOverlay media={media} status={status} makeReport={makeReport}/>*/}
    </div>
  );
}
/*
function ReportOverlay({media,status,makeReport}){
  const [reportText,setReportText] = useState("");
  const [showNotification,setShowNotification] = useState(false);
  const divisor = "\n-------------------------\n"
  function txtHandler(e){
    setReportText(e.target.value);
  }
  function copyThat(){
    navigator.clipboard.writeText(reportText + divisor +"\nStatus:\n" + status + divisor + "\nMedia:\n" + media);
    setShowNotification(true);
    setTimeout(()=>{setShowNotification(false)},1000);
  }
  return(
    <div className="absolute w-[60vw] h-[90vh] left-[20vw] top-[2vw] bg-green-800 z-10 p-2 rounded-xl">
      <button className="absolute top-0 right-0 p-2 text-2xl font-bold bg-green-500 hover:bg-green-700 text-white rounded-tr-xl rounded-bl-xl" onClick={makeReport}>Close</button>
      <p className="absolute left-[5vw] text-3xl text-white">Problembeschreibung:</p>
      <textarea className="absolute left-[5vw] top-[5vh] w-[50vw] h-[10vh] bg-white text-black resize-none" value={reportText} onChange={(e)=>txtHandler(e)}></textarea>
      <button className="absolute left-[5vw] top-[15vh] text-3xl p-2 bg-green-500 hover:bg-green-700 rounded-br-xl text-white z-20" onClick={()=>copyThat()}>In Zwischenablage kopieren</button>
      {showNotification && <p className="absolute left-[26vw] top-[16vh] text-xl text-white">Kopiert in Zwischenablage</p>}
      <a className="absolute left-[5vw] top-[20vh] text-3xl p-2 bg-green-500 hover:bg-green-700 rounded-b-xl text-white z-10"
         href={`mailto:${getSupportEmail()}?subject=Bug im ContentRating`}>Email-Programm Öffnen</a>
      <p className="absolute left-[5vw] text-lg text-white"></p>
      <ol className="absolute left-[5vw] top-[30vh] text-lg text-white list-decimal">
        <li>Problembeschreibung eingeben</li>
        <li>Button "In Zwischenablage kopieren" anklicken</li>
        <li>Gesamte Zwischenablage in Email an <a href={`mailto:${getSupportEmail()}?subject=Bug im ContentRating`}>{getSupportEmail()}</a> senden</li>
        <li>Screenshot mitsenden falls möglich</li>
      </ol>
    </div>
  );
}*/

// Diese Komponente beinhaltet die Hauptleiste am oberen Bildschirmrand
function Menubar({ ratingCat, globalJobQueue, selectNextSecondaryCategory, makeReport }) {
  // Hier werden alle ausstehenden Jobs der Unterkategorien für die Hauptkategorien zusammenaddiert
  let pc = globalJobQueue.photoRatingCounts
    ? globalJobQueue.photoRatingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let prc = globalJobQueue.photoReratingCounts
    ? globalJobQueue.photoReratingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let vc = globalJobQueue.videoRatingCounts
    ? globalJobQueue.videoRatingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let vrc = globalJobQueue.videoReratingCounts
    ? globalJobQueue.videoReratingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let texts = globalJobQueue.textRatingCounts
    ? globalJobQueue.textRatingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let comments = globalJobQueue.commentRatingCounts
    ? globalJobQueue.commentRatingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let tc = texts < 0 && comments < 0 ? -1 : (texts < 0 ? 0 : texts) + (comments < 0 ? 0 : comments);
  let lac = globalJobQueue.lustagentenRatingCounts
    ? globalJobQueue.lustagentenRatingCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let mc = globalJobQueue.modelVerificationCounts
    ? globalJobQueue.modelVerificationCounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let fsk16Chats = globalJobQueue.fsk16Chats
    ? globalJobQueue.fsk16Chats.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  let liveStreams = globalJobQueue.liveStreams
    ? globalJobQueue.liveStreams.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
    : -1;
  //const isInt = getCookie("internal") === "X";

  return (
    <div className="w-full">
      <div className="absolute top-0 left-0">
        <HomeButton
          ownCat={[99]}
          curCat={ratingCat}
          oc={() => selectNextSecondaryCategory("")}
          link={"/home"}
          text="Home"
        />
        {pc > -1 && (
          <MenuButton
            ownCat={[1]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("PhotoCheck")}
            link={"/photoRating"}
            text={"Photo(" + pc + ")"}
          />
        )}
        {prc > -1 && (
          <MenuButton
            ownCat={[2]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("PhotoRecheck")}
            link={"/photoRating"}
            text={"PhotoRecheck(" + prc + ")"}
          />
        )}
        {vc > -1 && (
          <MenuButton
            ownCat={[3]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("VideoCheck")}
            link={"/videoRating"}
            text={"Video(" + vc + ")"}
          />
        )}
        {vrc > -1 && (
          <MenuButton
            ownCat={[4]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("VideoRecheck")}
            link={"/videoRating"}
            text={"VideoRecheck(" + vrc + ")"}
          />
        )}
        {tc > -1 && (
          <MenuButton
            ownCat={[5, 6, 14, 15]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("TEXT")}
            link={"/commentRating"}
            text={"Text(" + tc + ")"}
          />
        )}
        {mc > -1 && (
          <MenuButton
            ownCat={[7]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("NOTHING")}
            link={"/modelVerification"}
            text={"Model Verification(" + mc + ")"}
          />
        )}
        {fsk16Chats > -1 && (
          <MenuButton
            ownCat={[8]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("NOTHING")}
            link={"/fsk16Review"}
            text={"FSK16 Chat Review"}
          />
        )}
        {liveStreams > -1 && (
          <MenuButton
            ownCat={[9]}
            curCat={ratingCat}
            oc={() => selectNextSecondaryCategory("NOTHING")}
            link={"/streamMonitor"}
            text={"Livestreams Monitoring"}
          />
        )}
        {lac > -1 && (
          <button
            className="my-1 mx-2 text-[15px] text-black dark:text-white hover:text-zanBlue dark:hover:text-zanBlue font-bold h-[30px] w-[90px] inline-block"
            onClick={() => loginToLustagenten()}
          >
            {"Lustagenten(" + lac + ")"}
          </button>
        )}
      </div>
      <div className=" absolute top-[2px] right-0">
        <MenuButton
          ownCat={[10]}
          curCat={ratingCat}
          oc={() => selectNextSecondaryCategory("HELP")}
          link={"/helpPage"}
          text={"Help"}
        />
        {/*<button className="mx-2 text-[15px] text-black dark:text-white hover:text-zanBlue dark:hover:text-zanBlue font-bold h-[30px] w-[60px] inline-block"
                onClick={()=>makeReport()}>Bugs</button>*/}
        <button
          className="ml-2 text-black dark:text-white hover:text-zanBlue dark:hover:text-zanBlue h-[30px] w-[30px] inline-block align-middle"
          onClick={() => {
            sessionStorage.clear();
            localStorage.removeItem("jobs");
            removeCookie("token");
            removeCookie("internal");
            window.location.href = "/login";
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="black"
            viewBox="0 0 20 20"
            strokeWidth={0}
            stroke="black"
            className="w-[18px] h-[18px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 2a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5A.75.75 0 0110 2zM5.404 4.343a.75.75 0 010 1.06 6.5 6.5 0 109.192 0 .75.75 0 111.06-1.06 8 8 0 11-11.313 0 .75.75 0 011.06 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

// Komponente für den Hausförmigen Button oben Links
function HomeButton({ ownCat, curCat, oc, link }) {
  return (
    <a
      id={"btn" + ownCat}
      href={link}
      rel="noreferrer"
      target={"_self"}
      className={`align-middle my-1 mr-2 h-[30px] inline-block ${
        ownCat.includes(curCat)
          ? "text-zanBlue"
          : "text-black dark:text-white hover:text-zanBlue dark:hover:text-zanBlue"
      }`}
      onClick={oc}
      onAuxClick={oc}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
        <path
          fillRule="evenodd"
          d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
          clipRule="evenodd"
        />
      </svg>
    </a>
  );
}

// Komponente für die Menübuttons
function MenuButton({ ownCat, curCat, oc, link, text }) {
  return (
    <a
      id={"btn" + ownCat}
      href={link}
      rel="noreferrer"
      target={"_self"}
      className={`my-1 mx-2 text-center text-[15px] ${
        ownCat.includes(curCat)
          ? "text-zanBlue"
          : "text-black dark:text-white hover:text-zanBlue dark:hover:text-zanBlue"
      } font-bold h-[30px] px-2 inline-block`}
      onClick={oc}
      onAuxClick={oc}
    >
      {text}
    </a>
  );
}

// Unterkategorien für das Bilderrating
function PictureCategories({ oc, globalJobQueue }) {
  return (
    <div className="h-full place-items-center">
      <PictureAndVideoCategoryButton catType={"FEED"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"SEDCARD"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"SHOP"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"FREE"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"DATING"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"POOL"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      {getCookie("internal") === "X" && (
        <PictureAndVideoCategoryButton catType={"CAMPAIGN"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
      )}
      <PictureAndVideoCategoryButton catType={"OTHER"} counts={globalJobQueue.photoRatingCounts} oc={oc} />
    </div>
  );
}

// Unterkategorien für das Bilderrerating
function PictureRecheckCategories({ oc, globalJobQueue }) {
  return (
    <div className="h-full place-items-center">
      <PictureAndVideoCategoryButton catType={"SEDCARD"} counts={globalJobQueue.photoReratingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"FREE"} counts={globalJobQueue.photoReratingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"DATING"} counts={globalJobQueue.photoReratingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"CAMPAIGN"} counts={globalJobQueue.photoReratingCounts} oc={oc} />
    </div>
  );
}

// Unterkategorien für das Videorating
function VideoCategories({ oc, globalJobQueue }) {
  return (
    <div className="h-full place-items-center">
      <PictureAndVideoCategoryButton catType={"SHOP"} counts={globalJobQueue.videoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"FREE"} counts={globalJobQueue.videoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"POOL"} counts={globalJobQueue.videoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"CAMPAIGN"} counts={globalJobQueue.videoRatingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"FEED"} counts={globalJobQueue.videoRatingCounts} oc={oc} />
    </div>
  );
}

// Unterkategorien für das Videorerating
function VideoRecheckCategories({ oc, globalJobQueue }) {
  return (
    <div className="h-full place-items-center">
      <PictureAndVideoCategoryButton catType={"SHOP"} counts={globalJobQueue.videoReratingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"FREE"} counts={globalJobQueue.videoReratingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"CAMPAIGN"} counts={globalJobQueue.videoReratingCounts} oc={oc} />
      <PictureAndVideoCategoryButton catType={"FEED"} counts={globalJobQueue.videoReratingCounts} oc={oc} />
    </div>
  );
}

// Unterkategorien für das Kommentar- und Textrating
function TextCategories({ oc, globalJobQueue }) {
  return (
    <div className="h-full place-items-center">
      <TextCategoryButton
        link={"/commentRating"}
        catType={"FEED"}
        ratingcounts={globalJobQueue.commentRatingCounts}
        oc={oc}
      />
      <TextCategoryButton
        link={"/commentRating"}
        catType={"SHOP"}
        ratingcounts={globalJobQueue.commentRatingCounts}
        oc={oc}
      />
      <TextCategoryButton link={"/textRating"} catType={"VX"} ratingcounts={globalJobQueue.textRatingCounts} oc={oc} />
      <TextCategoryButton
        link={"/powerMailing"}
        catType={"POWER_MAILING"}
        ratingcounts={globalJobQueue.powerMailing}
        oc={oc}
      />
      {getCookie("internal") === "X" && (
        <TextCategoryButton
          link={"/badwords"}
          catType={"badwords"}
          ratingcounts={[{ type: "badwords", counts: 0, label: "Badwords" }]}
          oc={oc}
        />
      )}
    </div>
  );
}

// Unterkategorien für die Hilfeseite
function HelpCategories({ oc }) {
  return (
    <div className="h-full place-items-center">
      <HelpCategoryButton catType={"AGE_RESTR"} oc={oc} text={"Jugendschutz"} />
      <HelpCategoryButton catType={"CON_RESTR"} oc={oc} text={"Content Restrictions"} />
      <HelpCategoryButton catType={"TOOL_HELP"} oc={oc} text={"Content Rating Tool"} />
      <HelpCategoryButton catType={"CONF_HELP"} oc={oc} text={"Confluence Anleitungen"} />
    </div>
  );
}

// Komponente für einen Button für eine Bild- oder Videounterkategorie
function PictureAndVideoCategoryButton({ catType, counts, oc }) {
  return (
    <button
      className={`text-center  rounded-full ${
        sessionStorage.getItem("sc") === catType
          ? "bg-zanBlue text-white"
          : counts.find((i) => i.type === catType).counts === 0
          ? "bg-zanGrey text-gray-400"
          : "text-black bg-zanGrey hover:bg-gray-400"
      } text-[15px] h-[30px] min-w-[80px] mr-2 px-2`}
      onClick={() => oc(catType)}
    >
      {" " +
        counts.find((i) => i.type === catType).label +
        " (" +
        (counts.find((i) => i.type === catType).counts -
          (sessionStorage.getItem("sc") === catType ? JSON.parse(sessionStorage.getItem("skipIds"))?.length ?? 0 : 0)) +
        ")"}
    </button>
  );
}

// Komponente für einen Button für eine Textunterkategorie
function TextCategoryButton({ link, catType, ratingcounts, oc }) {
  return (
    <button
      className={`text-center  rounded-full ${
        (
          catType === "VX"
            ? ratingcounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0) === 0
            : ratingcounts.find((i) => i.type === catType).counts === 0
        )
          ? "bg-zanGrey text-gray-400"
          : sessionStorage.getItem("sc") === catType
          ? "bg-zanBlue text-white"
          : "text-black bg-zanGrey hover:bg-gray-400"
      } text-[15px] h-[30px] min-w-[80px] mr-2 px-2`}
      onClick={() => {
        window.location.href = link;
        oc(catType);
      }}
    >
      {" " +
        ratingcounts.find((i) => i.type === catType).label +
        "(" +
        (catType === "VX"
          ? ratingcounts.map((i) => i.counts).reduce((acc, cur) => acc + cur, 0)
          : ratingcounts.find((i) => i.type === catType).counts) +
        ")"}
    </button>
  );
}

// Komponente für einen Button für eine Hilfeseite
function HelpCategoryButton({ catType, oc, text }) {
  let b = null;
  if (catType !== "CONF_HELP") {
    b = (
      <button
        className={`text-center rounded-full ${
          sessionStorage.getItem("sc") === catType ? "bg-zanBlue" : "bg-blue-500 hover:bg-blue-700"
        } text-white text-[15px] h-[30px] min-w-[80px] mx-1 px-2`}
        onClick={() => oc(catType)}
      >
        {" " + text}
      </button>
    );
  } else {
    b = (
      <a
        href={"https://campoint.atlassian.net/wiki/spaces/M/pages/2605875548/Content+Rating+Tool"}
        rel="noreferrer"
        target={"_blank"}
        className={`text-center rounded-full bg-blue-500 hover:bg-blue-700 text-white text-sm h-[30px] min-w-[80px] mx-1 px-2 pt-1 inline-block`}
      >
        {text}
      </a>
    );
  }
  return <>{b}</>;
}
