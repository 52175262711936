import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { getCookie, getFirstCat, getLoginFitness, saveLoginFitness, updateJobs } from "../scripts/xhrf";

export default function DatingNicknameApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 13;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in comms werden die Kommentare gespeichert
  const [logins,setLogins] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    onlineStatus : "all",
    fskStatus : "unrated",
    from : new Date(new Date()-86400000*14).toISOString().substring(0,10), // now - 14 days
    to : new Date().toISOString().substring(0,10),
    country : ""
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

    // Fetches Data on load
  useEffect(()=>{
    async function getData(){
      let c = await getLoginFitness(appStatus.onlineStatus,appStatus.fskStatus,appStatus.from,appStatus.to,appStatus.country);
      if(c.errors !== undefined){
        alert("Fehler: " + c.errors[0].message);
      }
      if(c.data.loginFitness === null){
        setSecondaryCategory(-1);
        return;
      }
      console.log(c.data.loginFitness);
      setLogins(c.data.loginFitness);
      setAppStatus({
        onlineStatus : appStatus.onlineStatus,
        fskStatus : appStatus.fskStatus,
        from : appStatus.from,
        to : appStatus.to,
        country : appStatus.country
      });
      setLoading(false);
      setDoRefresh(false);
      updateJobs();
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.onlineStatus,appStatus.fskStatus,appStatus.from,appStatus.to,appStatus.country,doRefresh]);

  async function rateLogins(){
    await saveLoginFitness(logins);
    setDoRefresh(true);
  }

  let contentF = <ContentField appStatus={appStatus} setAppStatus={setAppStatus} loading={loading} logins={logins} setLogins={setLogins} rateLogins={rateLogins}/>;
      
  return(
    <div className="dark:bg-gray-800 dark:text-white w-[99vw]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No comments to rate"}</p>}
    </div>
  );
}

function ContentField({appStatus,setAppStatus,loading,logins,setLogins,rateLogins}){

  const [fromDatePreview,setFromDatePreview] = useState(appStatus.from);
  const [toDatePreview,setToDatePreview] = useState(appStatus.to);
  const [countryPreview,setCountryPreview] = useState(appStatus.country);

  function changeFromDate(e){
    setFromDatePreview(e.target.value);
  }
  function changeToDate(e){
    setToDatePreview(e.target.value);
  }
  function changeCountry(e){
    setCountryPreview(e.target.value); 
  }
  function applyDates(){
    setAppStatus({
      ...appStatus,
      from : fromDatePreview,
      to : toDatePreview,
      country : countryPreview
    });
  }
  function changeOnlineStatus(e){
    setAppStatus({
      ...appStatus,
      onlineStatus : e.target.value
    });
  } 
  function changeFskStatus(e){
    setAppStatus({
      ...appStatus,
      fskStatus : e.target.value
    });
  }

  function setFskFitness(index,rating){
    let lst = logins.items;
    lst[index].fskFitness = rating;
    setLogins({
      ...logins,
      items : lst
    });
  }

  function setInternationalFitness(index,rating){
    let lst = logins.items;
    lst[index].internationalFitness = rating;
    setLogins({
      ...logins,
      items : lst
    });
  }

  let tb = null;
  let onlineOptions = null;
  let fskOptions = null;
  if(loading){    // Falls geladen wird, wird keine Tabelle angezeigt
    tb = <div className="w-full h-[10vh] bg-zanBg dark:bg-gray-700">
          <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  }else{
    tb = <table className="table-fixed border-separate text-center leading-tight">
          <thead>
            <tr className="bg-gray-400 dark:bg-gray-900">
              <th className="border-2 border-black w-[8vw]">User-ID</th>
              <th className="border-2 border-black w-[8vw]">Gender</th>
              <th className="border-2 border-black w-[6vw]">Category</th>
              <th className="border-2 border-black w-[6vw]">Country</th>
              <th className="border-2 border-black w-[10vw]">Login</th>
              <th className="border-2 border-black w-[18vw]">FSK Login fitness</th>
              <th className="border-2 border-black w-[22vw]">International Login fitness</th>
            </tr>
          </thead>
          <tbody>
            {logins.items.map((i,index) => <TableRow key={index} login={i} ind={index} even={index%2===0} fsk={setFskFitness} int={setInternationalFitness}/>)}
          </tbody>
        </table>;
      
      onlineOptions = logins.filter.allowedOnlineStatus.map((i,ind) => <option key={ind} value={i}>{i}</option>);
      fskOptions = logins.filter.allowedFskStatus.map((i,ind) => <option key={ind} value={i}>{i}</option>);
    }

  return(
    <div className="absolute top-[10vh] w-[99vw]">
      <div>
      <p className="inline mx-2">Online status:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.onlineStatus} onChange={(e)=>changeOnlineStatus(e)}>
          {onlineOptions}
        </select>
        <p className="inline mx-2">Login Fitness(fsk):</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.fskStatus} onChange={(e)=>changeFskStatus(e)}>
          {fskOptions}
        </select>
        <p className="inline mx-2">From:</p>
        <input className="w-56 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={fromDatePreview} onChange={(e)=>changeFromDate(e)}></input>
        <p className="inline mx-2">To:</p>
        <input className="w-56 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={toDatePreview} onChange={(e)=>changeToDate(e)}></input>
        <p className="inline mx-2">Country:</p>
        <input className="w-14 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={countryPreview} onChange={(e)=>changeCountry(e)}></input>
        <button className="text-zanBlue mx-2" onClick={applyDates}>Apply</button>
      </div>
      <div>
        <p className="inline mx-2">{`Found: ${logins.totalItems??0} check(s)`}</p>
      </div>
      <div className="w-[98vw] h-0.5 mx-auto bg-gray-700"></div>
      {tb}
      <button className="relative left-[31.5vw] top-2 w-[15vw] h-10 bg-lime-600 text-white rounded-3xl" onClick={rateLogins}>
        Save login fitness
      </button>
    </div>
  );
}

function TableRow({login,ind,even,fsk,int}){
  return(
    <tr className={`${(even?"bg-gray-50 text-sm dark:bg-gray-500":"bg-gray-200 text-sm dark:bg-gray-700")} hover:bg-blue-100 dark:hover:bg-blue-900 h-3`}>
      <td className="border-2 border-black">{getCookie('internal') === "X" ? <a target="_blank" rel="noreferrer" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+login.account.userId}>{login.account.userId}</a>
        : <p>{login.account.userId}</p>}</td>
      <td className="border-2 border-black">{login.account.profileGender}</td>
      <td className="border-2 border-black">{login.account.profileCategory}</td>
      <td className="border-2 border-black">{login.account.country}</td>
      <td className="border-2 border-black">{getCookie('internal') === "X" ? <a target="_blank" rel="noreferrer" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+login.account.userId}>{login.account.userName}</a> 
      : <p>{login.account.userName}</p>}</td>
      <td className="border-2 border-black">
        <div className="inline mx-3">
          <img className="inline h-5 w-5" alt="" src={login.fskFitness ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>fsk(ind,true)}></img>
          <p className="inline ml-2 text-sm" onClick={()=>fsk(ind,true)}>FSK-16</p>
        </div>
        <div className="inline mx-3">
          <img className="inline h-5 w-5" alt="" src={!login.fskFitness ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>fsk(ind,false)}></img>
          <p className="inline ml-2 text-sm" onClick={()=>fsk(ind,false)}>FSK-18</p>
        </div>
      </td>
      <td className="border-2 border-black">        <div className="inline mx-2">
          <img className="inline h-5 w-5" alt="" src={login.internationalFitness ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>int(ind,true)}></img>
          <p className="inline ml-2 text-sm" onClick={()=>int(ind,true)}>Accepted (Int.)</p>
        </div>
        <div className="inline mx-2">
          <img className="inline h-5 w-5" alt="" src={!login.internationalFitness ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>int(ind,false)}></img>
          <p className="inline ml-2 text-sm" onClick={()=>int(ind,false)}>Failed (DE-Only)</p>
        </div></td>
    </tr>
  );
}