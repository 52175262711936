import React, {useEffect, useRef, useState} from "react";
import NavBar from "./NavBar";
import { getCookie, getFirstCat, getNextLivestreams, liveStreamBadStream, liveStreamSendEmail } from "../scripts/xhrf";

export default function StreamMonitorApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 9;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in streams werden die Streaminformationen gespeichert
  const [streams,setStreams] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    type : "all",
    limit : (sessionStorage.getItem('limit')===null ? 8 : Number(sessionStorage.getItem('limit'))),
    page : (sessionStorage.getItem('page')==='null' ? 0 : Number(sessionStorage.getItem('page'))),
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

  useEffect(()=>{
    async function getData(){
      let s = await getNextLivestreams(appStatus.limit,appStatus.page,appStatus.type);
      console.log(s.data.liveStreams);
      setStreams(s.data.liveStreams);
      setLoading(false);
      setDoRefresh(false);
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.type,appStatus.page,appStatus.limit,doRefresh]);

  let contentF = <ContentField streams={streams} appStatus={appStatus} setAppStatus={setAppStatus} loading={loading}/>

      

  return(
    <div className="dark:bg-gray-800 dark:text-white min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p>{"No texts to rate"}</p>}
    </div>
  );
}

// Hauptfeld für die Streamkontrolle
function ContentField({streams,appStatus,setAppStatus,loading}){
  let sg =<div className="w-full h-[10vh] bg-zanBg dark:bg-slate-700">
            <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  if(!loading){
    sg = <StreamGrid streams={streams} appStatus={appStatus} setAppStatus={setAppStatus}/>
  }
  return(
    <div className="absolute top-[7vh] w-full">
      <TopBar streams={streams} appStatus={appStatus} setAppStatus={setAppStatus}/>
      {sg}
    </div>
  );
}

// Komponente zur Auswahl und Steuerung der anzeigbaren Streams
function TopBar({streams,appStatus,setAppStatus}){

  var maxPages = Math.ceil(streams.totalItems/Number(appStatus.limit));

  function firstPage(){
    setAppStatus({
      ...appStatus,
      page : 0
    });
    sessionStorage.setItem('page',0);
    window.location.reload();
  }
  function prevPage(){
    if(appStatus.page > 0){
      setAppStatus({
        ...appStatus,
        page : appStatus.page-1
      });
      sessionStorage.setItem('page',appStatus.page-1);
      window.location.reload();
    }
  }
  function nextPage(){
    if(appStatus.page+1<maxPages){
      setAppStatus({
        ...appStatus,
        page : appStatus.page+1
      });
      sessionStorage.setItem('page',appStatus.page+1);
      window.location.reload();
    }
  }
  function lastPage(){
    setAppStatus({
      ...appStatus,
      page : maxPages-1
    });
    sessionStorage.setItem('page',maxPages-1);
    window.location.reload();
  }

  function changeType(e){
    setAppStatus({
      ...appStatus,
      type : e.target.value
    })
  }
  function changeLimit(e){
    sessionStorage.setItem('limit',e.target.value);
    setAppStatus({
      ...appStatus,
      limit : e.target.value
    })
  }

  return(
    <div>
      <p className="inline mx-2">Stream:</p>
      <select className="inline mx-2 bg-zanGrey rounded-full h-[30px] px-3" value={appStatus.type} onChange={(e)=>changeType(e)}>
        <option value={"all"}>--All--</option>
        <option value={"webRTC"}>WebRTC</option>
        <option value={"RTMP"}>RTMP</option>
      </select>
      <p className="inline mx-2">{"Total Streams : " + streams.totalItems}</p>
      <p className="inline ml-2">Streams per Page:</p>
      <select className="inline mx-2 bg-zanGrey rounded-full h-[30px] px-3" value={appStatus.limit} onChange={(e)=>changeLimit(e)}>
        <option value={8}>8</option>
        <option value={12}>12</option>
      </select>
      <p className="inline mx-2">{`Page: ${appStatus.page+1} of ${maxPages}`}</p>
      <button className="inline mx-2" onClick={firstPage}>{"<<First"}</button>
      <button className="inline mx-2" onClick={prevPage}>{"<Prev"}</button>
      <button className="inline mx-2" onClick={nextPage}>{"Next>"}</button>
      <button className="inline mx-2" onClick={lastPage}>{"Last>>"}</button>
    </div>
  );
}

// Komponente in der die Streams in einem Raster angeordnet sind
function StreamGrid({streams,appStatus,setAppStatus}){

  let str = streams.items.map((s,index) => <Stream key={index} stream={s} reasons={streams.allowedEmailTemplates}/>)

  return(
    <div className="grid grid-cols-4 auto-rows-auto grid-rows-2">
      {str}
    </div>
  );
}

// Komponente, die ineen einzelnen Stream darstellt
function Stream({stream,reasons}){

  const [selectedReason,setSelectedReason] = useState(reasons[0].id);
  const [showEmailReasons,setShowEmailReasons] = useState(stream.emailSentDate === null);
  const [isReported,setIsReported] = useState(stream.isReportedAsBadStream);

  const selectRef = useRef(null);

  let emailReasons = reasons.map((r,index) => <option key={index} value={r.id}>{r.label}</option>)

  function changeReason(e){
    setSelectedReason(e.target.value);
  }

  function sendEmail(){
    //console.log(selectRef.current.value);   // ID 
    liveStreamSendEmail(stream.modelId,selectRef.current.value);
    setShowEmailReasons(false);
    stream.emailSentDate = String(new Date());
    //console.log(stream.emailSentDate);
  }
  function sendReport(){
    liveStreamBadStream(stream.streamId);
    setIsReported(true);
  }
      //  TODO link to profile in username
  return(
    <div className="relative h-[325px] w-[470px] aspect-video bg-white rounded-3xl my-0.5">
      {getCookie('internal') === "X" ? <a className={`absolute top-2 left-2 w-[100px] h-8 text-sm font-bold px-1 pt-1.5 ${stream.isOnline ? "text-green-500" : "text-red-500"}`} rel="noreferrer" target="_blank" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+stream.modelId}>{stream.modelName}</a>
      : <p className={`absolute top-2 left-2 w-[100px] h-8 text-sm font-bold px-1 pt-1.5 ${stream.isOnline ? "text-green-500" : "text-red-500"}`}>{stream.modelName}</p>}
      {showEmailReasons ? <><select ref={selectRef} id={`emailSelector`} className="absolute top-2 left-[108px] w-[190px] h-8 text-sm bg-gray-200 p-1" value={selectedReason} onChange={(e)=>changeReason(e)}>
          {emailReasons}
        </select>
        <button className="absolute top-2 left-[298px] w-[40px] h-8 text-sm bg-gray-200 p-1" onClick={sendEmail}>Send</button>
      </> :
        <p className="absolute top-2 left-[108px] w-[220px] h-8 text-sm text-black pl-2 pt-1.5">{"Email sent at:" + stream.emailSentDate.substring(0,10) + " " + stream.emailSentDate.substring(11,16)}</p>
      }
      {isReported ? <p className="absolute top-2 left-[338px] w-[126px] h-8 text-sm px-1 pt-1.5">{"Already Reported"}</p> :
      <button className="absolute top-2 left-[338px] w-[126px] h-8 text-sm px-1 pt-1.5" onClick={sendReport}>Report bad Stream</button>
      }
      {getCookie('internal') === "X" ? <a href={"https://vxadmin.ops.xxx/CONTROLVX/Host/ControlChat?usrid=" + stream.modelId} rel="noreferrer" target="_blank"><img className="absolute top-10 left-[5px] h-[280px] w-[460px]" src={stream.url} alt="no Stream available"></img></a>
      : <img className="absolute top-10 left-[5px] h-[280px] w-[460px]" src={stream.url} alt="no Stream available"></img>}
    </div>
  );
}