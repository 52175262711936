import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { getCookie, getFirstCat, getPowermails, ratePowermails, updateJobs } from "../scripts/xhrf";
import Highlighter from "react-highlight-words";

export default function PowerMailingApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 14;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in comms werden die Kommentare gespeichert
  const [mails,setMails] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    status : "new",
    page : 0,
    limit : 20,
    pid: "",
    userId: ""
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

    // Fetches Data on load
  useEffect(()=>{
    async function getData(){
      let c = await getPowermails(appStatus.status,appStatus.limit,appStatus.page,appStatus.pid,appStatus.userId);
      if(c.errors !== undefined){
        alert("Fehler: " + c.errors[0].message);
      }
      if(c.data.powerMailingCheck === null){
        setSecondaryCategory(-1);
        return;
      }
      console.log(c.data.powerMailingCheck);
      setMails(c.data.powerMailingCheck);
      setAppStatus({
        status : appStatus.status,
        page : appStatus.page,
        limit : appStatus.limit,
        pid: appStatus.pid,
        userId: appStatus.userId,
        selected : Array(c.data.powerMailingCheck.items.length).fill(true),
        allSelected : true
      });
      setLoading(false);
      setDoRefresh(false);
      updateJobs();
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.status,appStatus.page,appStatus.limit,appStatus.pid,appStatus.userId,doRefresh]);

  let contentF = <ContentField appStatus={appStatus} setAppStatus={setAppStatus} loading={loading} mails={mails} setMails={setMails} setDoRefresh={setDoRefresh}/>;
      
  return(
    <div className="dark:bg-gray-800 dark:text-white w-[99vw]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No comments to rate"}</p>}
    </div>
  );
}

function ContentField({appStatus,setAppStatus,loading,mails,setMails,setDoRefresh}){

  const [pmIdPreview,setPmIdPreview] = useState(appStatus.pid);
  const [userIdPreview,setuserIdPreview] = useState(appStatus.userId);
  var maxPages = Math.ceil(mails.totalItems/Number(appStatus.limit));

  // Bewertet einen einzelnen Kommentar
  async function rateMail(rat,id,ind){
    await ratePowermails([{id: id, isAccepted: rat}]);
    let c = mails;
    c.items.splice(ind,1);
    setMails(c);
    let s = appStatus.selected;
    s.splice(ind,1);
    setAppStatus({
      ...appStatus,
      selected : s
    });
    if(c.items.length===0){
      setDoRefresh(true);
    }
  }

  // Bewertet mehrere Kommentare
  async function rateSelected(rat){
    await ratePowermails(mails.items.filter((m,ind) => appStatus.selected[ind]).map((m,ind) => JSON.parse(`{"id": ${m.id},"isAccepted": ${rat}}`)));
    let c = mails;
    let s = appStatus.selected;
    for(let i = appStatus.selected.length-1 ; i>=0 ; i--){
      if(appStatus.selected[i]){
        c.items.splice(i,1);
        s.splice(i,1);
      }
    }
    setMails(c);
    setAppStatus({
      ...appStatus,
      selected : s
    });
    if(appStatus.allSelected){
      setDoRefresh(true);
    }
  }

  function applySearch(){
    setAppStatus({
      ...appStatus,
      pid: pmIdPreview,
      userId: userIdPreview
    });
    setDoRefresh(true);
  }
  function changeStatus(e){
    setAppStatus({
      ...appStatus,
      status : e.target.value
    });
  }
  function changeLimit(e){
    setAppStatus({
      ...appStatus,
      limit : e.target.value
    });
  }
  function selectMail(e,ind){
    let l = appStatus.selected;
    l[ind] = e.target.checked;
    setAppStatus({
      ...appStatus,
      selected : l
    });
  }
  function selectAll(e){
    setAppStatus({
      ...appStatus,
      selected : appStatus.selected.fill(e.target.checked),
      allSelected : e.target.checked
    })
  }

  function firstPage(){
    setAppStatus({
      ...appStatus,
      page : 0
    });
  }
  function prevPage(){
    if(appStatus.page > 0){
      setAppStatus({
        ...appStatus,
        page : appStatus.page-1
      });
    }
  }
  function nextPage(){
    if(appStatus.page+1<maxPages){
      setAppStatus({
        ...appStatus,
        page : appStatus.page+1
      });
    }
  }
  function lastPage(){
    setAppStatus({
      ...appStatus,
      page : maxPages-1
    });
  }

  let tb = null;
  let statusOptions = null;
  if(loading){    // Falls geladen wird, wird keine Tabelle angezeigt
    tb = <div className="w-full h-[10vh] bg-zanBg dark:bg-gray-700">
          <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  }else{
    tb = <table className="table-fixed border-separate text-center leading-tight">
          <thead>
            <tr className="bg-gray-400 dark:bg-gray-900">
              <th className="border-2 border-black w-[2vw]"><input className="scale-125" type={"checkbox"} onChange={(e)=>selectAll(e)} checked={appStatus.allSelected}></input></th>
              <th className="border-2 border-black w-[6vw]"></th>
              <th className="border-2 border-black w-[3vw]">ID</th>
              <th className="border-2 border-black w-[8vw]">Created</th>
              <th className="border-2 border-black w-[8vw]">Host</th>
              <th className="border-2 border-black w-[6vw]">Target group</th>
              <th className="border-2 border-black w-[4vw]">Recipients</th>
              <th className="border-2 border-black w-[8vw]">Sent</th>
              <th className="border-2 border-black w-[54vw]">Subject / Text</th>
            </tr>
          </thead>
          <tbody>
            {mails.items.map((i,index) => <TableRow key={index} mail={i} ind={index} even={index%2===0} cb={selectMail} appStatus={appStatus} rateMail={rateMail}/>)}
          </tbody>
        </table>;
      
      statusOptions = mails.filter.allowedFskStatus.map((i,ind) => <option key={ind} value={i}>{i}</option>);
    }

    // Beinhaltet die Buttons Approve und Delete Selected
  let as = <div className="mx-2 my-2 w-[25vw]">
    <button className="inline mx-2 bg-zanGrey px-2 h-[30px] rounded-full" disabled>{"Selected "+appStatus.selected?.filter(i=>i===true).length+":"}</button>
    <button className="inline mx-2 bg-lime-600 px-2 h-[30px] rounded-full text-white" onClick={()=>rateSelected(true)}>Approve selected</button>
    <button className="inline mx-2 bg-zanRed px-2 h-[30px] rounded-full text-white" onClick={()=>rateSelected(false)}>Delete selected</button>
   </div>

  return(
    <div className="absolute top-[10vh] w-[99vw]">
      <div>
        <p className="inline mx-2">Status:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.status} onChange={(e)=>changeStatus(e)}>
          {statusOptions}
        </select>
        <p className="inline mx-2">Limit:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.limit} onChange={(e)=>changeLimit(e)}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </select>
        <p className="inline mx-2">Powermail Id:</p>
        <input type="number" className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-24" value={pmIdPreview} onChange={(e)=>setPmIdPreview(e.target.value)}></input>
        <p className="inline mx-2">User Id:</p>
        <input type="number" className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-24" value={userIdPreview} onChange={(e)=>setuserIdPreview(e.target.value)}></input>
        <button className="inline mx-1 text-zanBlue w-12" onClick={applySearch}>Apply</button>
      </div>
      <div>
        <p className="inline mx-2">{`Found: ${mails.totalItems??0} check(s)`}</p>
        <p className="inline mx-2">{`Page: ${Number(appStatus.page)+1} of ${maxPages}`}</p>
        <button className="inline mx-1" onClick={()=>firstPage()}>{"<<First"}</button>
        <button className="inline mx-1" onClick={()=>prevPage()}>{"<Previous"}</button>
        <button className="inline mx-1" onClick={()=>nextPage()}>{"Next>"}</button>
        <button className="inline mx-1" onClick={()=>lastPage()}>{"Last>>"}</button>
      </div>
      <div className="w-[98vw] h-0.5 mx-auto bg-gray-700"></div>
      {as}
      {tb}
      {as}
    </div>
  );
}

function TableRow({mail,ind,even,cb,appStatus,rateMail}){
  return(
    <tr className={`${(even?"bg-gray-50 text-sm dark:bg-gray-500":"bg-gray-200 text-sm dark:bg-gray-700")} hover:bg-blue-100 dark:hover:bg-blue-900 h-3`}>
      <td className="border-2 border-black"><input className="scale-125" type={"checkbox"} onChange={(e)=>cb(e,ind)} checked={appStatus.selected[ind]}></input></td>
      <td className="border-2 border-black">
        <button className="hover:text-blue-800 text-lime-600" onClick={()=>rateMail(true,mail.id,ind)}>Approve</button>
        <div className="h-2"></div>
        <button className="hover:text-blue-800 text-zanRed" onClick={()=>rateMail(false,mail.id,ind)}>Reject</button>
      </td>
      <td className="border-2 border-black">{mail.id}</td>
      <td className="border-2 border-black">{mail.createdAt.substring(0,10) + " " + mail.createdAt.substring(11,16)}</td>
      <td className="border-2 border-black">{getCookie('internal') !== "X" ? <a target="_blank" rel="noreferrer" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+mail.account.userId}>{mail.account.userName}</a>
        : <p>{mail.account.userName}</p>}</td>
      <td className="border-2 border-black">{mail.targetGroup}</td>
      <td className="border-2 border-black">{mail.recipientsCount}</td>
      <td className="border-2 border-black">{mail.sentAt === null ? "" : mail.sentAt.substring(0,10) + " " + mail.sentAt.substring(11,16)}</td>
      <td className="border-2 border-black">
        <Highlighter
          searchWords={mail.suspectedWords}
          textToHighlight ={mail.subject}
          className={"font-bold"}
        />
        <div></div>
        <Highlighter
          searchWords={mail.suspectedWords}
          textToHighlight ={mail.text}
          className={""}
        />
      </td>
    </tr>
  );
}