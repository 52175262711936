import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { getFirstCat, getLoginFitness, saveLoginFitness, updateJobs } from "../scripts/xhrf";

export default function BadwordsApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 15;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in comms werden die Kommentare gespeichert
  const [logins,setLogins] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    onlineStatus : "all",
    fskStatus : "unrated",
    from : new Date(new Date()-86400000*14).toISOString().substring(0,10), // now - 14 days
    to : new Date().toISOString().substring(0,10),
    country : ""
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

    // Fetches Data on load
  useEffect(()=>{
    async function getData(){
      let c = await getLoginFitness(appStatus.onlineStatus,appStatus.fskStatus,appStatus.from,appStatus.to,appStatus.country);
      if(c.errors !== undefined){
        alert("Fehler: " + c.errors[0].message);
      }
      if(c.data.loginFitness === null){
        setSecondaryCategory(-1);
        return;
      }
      console.log(c.data.loginFitness);
      setLogins(c.data.loginFitness);
      setAppStatus({
        onlineStatus : appStatus.onlineStatus,
        fskStatus : appStatus.fskStatus,
        from : appStatus.from,
        to : appStatus.to,
        country : appStatus.country
      });
      setLoading(false);
      setDoRefresh(false);
      updateJobs();
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.onlineStatus,appStatus.fskStatus,appStatus.from,appStatus.to,appStatus.country,doRefresh]);

  async function rateLogins(){
    await saveLoginFitness(logins);
    setDoRefresh(true);
  }

  let contentF = <ContentField appStatus={appStatus} setAppStatus={setAppStatus} loading={loading} logins={logins} setLogins={setLogins} rateLogins={rateLogins}/>;
      
  return(
    <div className="dark:bg-gray-800 dark:text-white w-[99vw]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No comments to rate"}</p>}
    </div>
  );
}

function ContentField({appStatus,setAppStatus,loading,logins,setLogins,rateLogins}){

  const [userIdPreview,setUserIdPreview] = useState("");
  const [wordFilterPreview,setWordFilterPreview] = useState("");
  const [bllIdPreview,setBllIdPreview] = useState("");

  var maxPages = 2;//Math.ceil(comms.totalUnratedComments/Number(appStatus.limit));

  function applyDates(){
    setAppStatus({
      ...appStatus
    });
  }
  function changeOnlineStatus(e){
    setAppStatus({
      ...appStatus,
      onlineStatus : e.target.value
    });
  } 
  function changeFskStatus(e){
    setAppStatus({
      ...appStatus,
      fskStatus : e.target.value
    });
  }

  function setFskFitness(index,rating){
    let lst = logins.items;
    lst[index].fskFitness = rating;
    setLogins({
      ...logins,
      items : lst
    });
  }

  function setInternationalFitness(index,rating){
    let lst = logins.items;
    lst[index].internationalFitness = rating;
    setLogins({
      ...logins,
      items : lst
    });
  }

  function firstPage(){
    setAppStatus({
      ...appStatus,
      page : 0
    });
  }
  function prevPage(){
    if(appStatus.page > 0){
      setAppStatus({
        ...appStatus,
        page : appStatus.page-1
      });
    }
  }
  function nextPage(){
    if(appStatus.page+1<maxPages){
      setAppStatus({
        ...appStatus,
        page : appStatus.page+1
      });
    }
  }
  function lastPage(){
    setAppStatus({
      ...appStatus,
      page : maxPages-1
    });
  }

  let tb = null;
  if(loading){    // Falls geladen wird, wird keine Tabelle angezeigt
    tb = <div className="w-full h-[10vh] bg-zanBg dark:bg-gray-700">
          <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  }else{
    tb = <table className="table-fixed border-separate text-center leading-tight">
          <thead>
            <tr className="bg-gray-400 dark:bg-gray-900">
              <th className="border-2 border-black w-[2vw]"></th>
              <th className="border-2 border-black w-[5vw]">Confirm</th>
              <th className="border-2 border-black w-[5vw]">Rec. History</th>
              <th className="border-2 border-black w-[5vw]">Send Email</th>
              <th className="border-2 border-black w-[8vw]">Forwarder</th>
              <th className="border-2 border-black w-[4vw]">Source</th>
              <th className="border-2 border-black w-[48vw]">Message</th>
              <th className="border-2 border-black w-[8vw]">Recipient</th>
              <th className="border-2 border-black w-[10vw]">Date</th>
              <th className="border-2 border-black w-[4vw]">BLL ID</th>
            </tr>
          </thead>
          <tbody>
            {logins.items.map((i,index) => <TableRow key={index} login={i} ind={index} even={index%2===0} fsk={setFskFitness} int={setInternationalFitness}/>)}
          </tbody>
        </table>;
      
    }

  return(
    <div className="absolute top-[10vh] w-[99vw]">
      <div>
        <p className="inline mx-2">Status:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.onlineStatus} onChange={(e)=>changeOnlineStatus(e)}>
          <option>--ALL--</option>
        </select>
        <p className="inline mx-2">Source:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.fskStatus} onChange={(e)=>changeFskStatus(e)}>
          <option>--ALL--</option>
        </select>
        <p className="inline mx-2">Forwarder:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.onlineStatus} onChange={(e)=>changeOnlineStatus(e)}>
          <option>--ALL--</option>
        </select>
        <p className="inline mx-2">Period:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.onlineStatus} onChange={(e)=>changeOnlineStatus(e)}>
          <option>Last 2 Weeks</option>
        </select>
        <p className="inline mx-2">User-IDs:</p>
        <input className="w-40 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={userIdPreview} onChange={(e)=>setUserIdPreview(e.target.value)}></input>
        <p className="inline mx-2">Word filter:</p>
        <input className="w-40 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={wordFilterPreview} onChange={(e)=>setWordFilterPreview(e.target.value)}></input>
        <p className="inline mx-2">BLL ID:</p>
        <input className="w-40 bg-zanGrey rounded-full h-[30px] px-3" type="text" value={bllIdPreview} onChange={(e)=>setBllIdPreview(e.target.value)}></input>
        <button className="text-zanBlue mx-2" onClick={applyDates}>Apply</button>
      </div>
      <div>
        <p className="inline mx-2">{`Found: ${logins.totalItems??0} check(s)`}</p>
        <p className="inline mx-2">{`Shown: ${logins.items?.length??0} feedbacks`}</p>
        <p className="inline mx-2">{`Page: ${Number(appStatus.page)+1} of ${maxPages}`}</p>
        <button className="inline mx-1" onClick={()=>firstPage()}>{"<<First"}</button>
        <button className="inline mx-1" onClick={()=>prevPage()}>{"<Previous"}</button>
        <button className="inline mx-1" onClick={()=>nextPage()}>{"Next>"}</button>
        <button className="inline mx-1" onClick={()=>lastPage()}>{"Last>>"}</button>
      </div>
      <div className="w-[98vw] h-0.5 mx-auto bg-gray-700"></div>
      {tb}
      {/*<button className="relative left-[31.5vw] top-2 w-[15vw] h-10 bg-lime-600 text-white rounded-3xl" onClick={rateLogins}>
        Save login fitness
  </button>*/}
    </div>
  );
}

function TableRow({login,ind,even,fsk,int}){
  return(
    <tr className={`${(even?"bg-gray-50 text-sm dark:bg-gray-500":"bg-gray-200 text-sm dark:bg-gray-700")} hover:bg-blue-100 dark:hover:bg-blue-900 h-3`}>
      <td className="border-2 border-black"></td>
      <td className="border-2 border-black">Confirm</td>
      <td className="border-2 border-black">Rec. History</td>
      <td className="border-2 border-black">Send Email</td>
      <td className="border-2 border-black">Forwarder</td>
      <td className="border-2 border-black">Source</td>
      <td className="border-2 border-black">Message</td>
      <td className="border-2 border-black">Recipient</td>
      <td className="border-2 border-black">Date</td>
      <td className="border-2 border-black">BLL ID</td>
    </tr>
  );
}