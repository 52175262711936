import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { approveVerification, getFirstCat, getNextVerification, rejectVerification } from "../scripts/xhrf";
import { NewUserInfo } from "./PageSnippets";

export default function ModelVerificationApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 7;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // In verification werden die Informationen über den Verifikationsprozess gespeichert
  const [verification,setVerification] = useState({});
  
  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    showRejected : false,
    sort: false
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

  // fetches data on load
  useEffect(()=>{
    async function getData(){
      let searchString = "";
      if(window.location.pathname !=="/modelVerification"){
        searchString = window.location.pathname.split("/")[2];
      }
      let v = await getNextVerification(searchString,appStatus.showRejected,appStatus.sort);
      if(v.errors !== undefined){
        alert("Fehler: " + v.errors[0].message);
      }
      if(v.data.modelVerificationSupport.account === null){
        setSecondaryCategory(-1);
      }else{
        setSecondaryCategory(1);
      }
      console.log(v);
      setVerification(v.data.modelVerificationSupport);
      setAppStatus({
        showRejected : appStatus.showRejected,
        sort : appStatus.sort
      });
      setLoading(false);
      setDoRefresh(false);
    }
    setLoading(true);
    getData();
  },[secondaryCategory,doRefresh,appStatus.showRejected,appStatus.sort]);

  // Verifiziert das Model
  async function applyVerify(){
    setLoading(true);
    await approveVerification(verification.account.userId);
    sessionStorage.setItem("previous",verification.account.userId);
    setDoRefresh(true);
  }

  // Lehnt die Verifikation ab
  async function rejectVerify(reason){
    setLoading(true);
    await rejectVerification(verification.account.userId,reason);
    sessionStorage.setItem("previous",verification.account.userId);
    setDoRefresh(true);
  }

  // Überspringt die aktuelle Verifikation
  function skipVerification(){
    let ss = sessionStorage.getItem('skipIds')??"[]";
    ss = JSON.parse(ss);
    if(!ss.includes(verification.account.userId)){
      ss.push(verification.account.userId);
    }
    if(window.location.pathname !== "/modelVerification"){
      window.location.pathname = "/modelVerification";
    }
    sessionStorage.setItem('skipIds',JSON.stringify(ss));
    sessionStorage.setItem("previous",verification.account.userId);
    setDoRefresh(true);
  }

  function previousVerification(){
    if(sessionStorage.getItem("previous") !== null ){
      window.location.pathname = "/modelVerification/" + sessionStorage.getItem("previous");
      sessionStorage.removeItem("previous");
    }
  }

  // Zeigt abgelehnte Verifikationen an
  function changeShowRejected(){
    setAppStatus({
      ...appStatus,
      showRejected : !appStatus.showRejected
    });
  }

  let contentF = <ModelVerification
      verification={verification}
      skipVerification={skipVerification}
      applyVerify={applyVerify}
      rejectVerify={rejectVerify}
      previousVerification={previousVerification}
    />

  if(loading){
    contentF = null;
  }      

  return(
    <div className="dark:bg-gray-800 dark:text-white min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      <SearchAndFilter appStatus={appStatus} setAppStatus={setAppStatus}/>
      <div className="absolute top-[4vh] left-2 w-[200px] h-[30px] bg-white rounded-full">
        <input className="my-2 mx-3" type="checkbox" id="showRej" checked={appStatus.showRejected} onChange={changeShowRejected}></input>
        <label htmlFor="showRej" className="text-black">{"Show Rejected ("+verification.totalRejectedModels+")"}</label>
      </div>
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No Models to verify"}</p>}
    </div>
  );
}

// Hauptkomponente für die Modelverifikation
function ModelVerification({verification,skipVerification,applyVerify,rejectVerify,previousVerification}){

  const [rejReason,setRejReason] = useState("");

  let previousExists = sessionStorage.getItem("previous") !== null;

  function changeRejReason(txt){
    setRejReason(txt)
  }

  /*
  let idC = verification.idCards.map((i,index)=><div key={index}><p>{i.sideName}</p><a href={i.url} target="_blank" rel="noreferrer"><img alt="Bild konnte nich angegezeigt werden" src={i.url}></img></a></div>);
  idC.sort((a,b)=>{return (a < b ? 1 : -1)})
  let idS = verification.idShots.map((i,index)=><div key={index}><a href={i.url} target="_blank" rel="noreferrer"><img alt="Bild konnte nich angegezeigt werden" src={i.url}></img></a></div>);
  */

  let idC = []; //verification.mrfs.map((i,index)=><object key={index} className="mx-auto" width={"96%"} height={"600px"} data={i.url}><p>Unable to display PDF</p></object>);
  for(let e in verification.idCards){
    if(verification.idCards[e].url.match(/\.pdf\?/g) === null){
      idC.push(<a href={verification.idCards[e].url} target="_blank" rel="noreferrer"><img alt={verification.idCards[e].url + " konnte nich angegezeigt werden (anklicken zum Herunterladen)"} src={verification.idCards[e].url}></img></a>);
    }else{
      idC.push(<object className="mx-auto" width={"96%"} height={"600px"} data={verification.idCards[e].url}><p>Unable to display PDF</p></object>);
    }
  }
  idC.sort((a,b)=>{return (a < b ? 1 : -1)})

  let idS = []; //verification.mrfs.map((i,index)=><object key={index} className="mx-auto" width={"96%"} height={"600px"} data={i.url}><p>Unable to display PDF</p></object>);
  for(let e in verification.idShots){
    if(verification.idShots[e].url.match(/\.pdf\?/g) === null){
      idS.push(<a href={verification.idShots[e].url} target="_blank" rel="noreferrer"><img alt={verification.idShots[e].url + " konnte nich angegezeigt werden (anklicken zum Herunterladen)"} src={verification.idShots[e].url}></img></a>);
    }else{
      idS.push(<object className="mx-auto" width={"96%"} height={"600px"} data={verification.idShots[e].url}><p>Unable to display PDF</p></object>);
    }
  }

  let mrf = []; //verification.mrfs.map((i,index)=><object key={index} className="mx-auto" width={"96%"} height={"600px"} data={i.url}><p>Unable to display PDF</p></object>);
  for(let e in verification.mrfs){
    if(verification.mrfs[e].url.match(/\.pdf\?/g) === null){
      mrf.push(<a href={verification.mrfs[e].url} target="_blank" rel="noreferrer"><img alt={verification.mrfs[e].url + " konnte nich angegezeigt werden (anklicken zum Herunterladen)"} src={verification.mrfs[e].url}></img></a>);
    }else{
      mrf.push(<object className="mx-auto" width={"96%"} height={"600px"} data={verification.mrfs[e].url}><p>Unable to display PDF</p></object>);
    }
  }
  return(
    <div className="absolute top-[8vh]">
      <NewUserInfo 
        pos={"absolute left-[0.5vw] top-0 h-[15vh] w-[47vw]"}
        maxTextHeight={"h-[6vh]"}
        acc={verification.account}
        showOrigin={true}
      />
      <div className="absolute top-0 left-[48vw] h-[15vh] w-[51vw] bg-white rounded-3xl">
        <button className="absolute top-[1vh] left-[0.5vw] h-[7vh] w-[12vw] text-3xl font-bold rounded-2xl text-white bg-lime-600" onClick={()=>applyVerify()}>Verified</button>
        <button className={`absolute top-[1vh] left-[13vw] h-[7vh] w-[12vw] text-3xl font-bold rounded-2xl ${previousExists ? "text-zanBlue border-zanBlue" : "text-disabledGrey border-disabledGrey"}  bg-white  border-4`} onClick={previousVerification}>Previous</button>
        <button className="absolute top-[1vh] left-[25.5vw] h-[7vh] w-[12vw] text-3xl font-bold rounded-2xl text-zanBlue bg-white border-zanBlue border-4" onClick={skipVerification}>Skip</button>
        <button className="absolute top-[1vh] left-[38vw] h-[7vh] w-[12vw] text-3xl font-bold rounded-2xl text-white bg-zanRed" onClick={()=>rejectVerify(rejReason)}>Reject</button>
        <input type="text" value={rejReason} onChange={(e)=>changeRejReason(e.target.value)} placeholder="Rejection Reason" className="absolute top-[9vh] left-[0.5vw] h-[5vh] w-[50vw] bg-disabledGrey text-disabledGreyText rounded-3xl px-3"></input>
      </div>
      <div className="absolute top-[16vh] left-[0.5vw] h-[73vh] w-[99vw]">
        <div className="absolute top-0 left-[0vw] h-full w-[32.5vw] bg-white rounded-3xl overflow-hidden overflow-y-scroll px-1">
          <p className="font-bold text-center pt-2 text-2xl">{"Identity proof"}</p>
          {idC}
        </div>
        <div className="absolute top-0 left-[33vw] h-full w-[32.5vw] bg-white rounded-3xl overflow-hidden overflow-y-scroll px-1">
          <p className="font-bold text-center pt-2 text-2xl">{"ID Shot"}</p>
          {idS}
        </div>
        <div className="absolute top-0 left-[66vw] h-full w-[32.5vw] bg-white rounded-3xl overflow-hidden overflow-y-scroll px-1">
          <p className="font-bold text-center pt-2 text-2xl">{"Model Release Form"}</p>
          {mrf}
        </div>
      </div>
    </div>
  );
}

function SearchAndFilter({appStatus,setAppStatus}){
  const [searchValue,setSearchValue] = useState("");

  function changeSort(e){
    setAppStatus({
      ...appStatus,
      sort : e.target.value
    })
  }

  function updateSearch(e){
    setSearchValue(e.target.value);
  }
  function searchAlbum(){
    if(searchValue === ""){
      window.location.pathname = "/modelVerification";
    }else{
      window.location.pathname = "/modelVerification/" + searchValue.trim();
    }
  }
  
  useEffect(()=>{
    function pressedEnter(e){
      if(e.key === "Enter"){
        searchAlbum();
      }
    }
    let sf = document.getElementById("searchField");
    sf.addEventListener("keydown",pressedEnter);
    return () => sf.removeEventListener("keydown",pressedEnter);
  });

  return(
    <div className="absolute top-[4vh] right-[0.5vw]">
      <input id="searchField" className="bg-zanGrey rounded-full px-3 h-[30px] text-[15px]" type="text" placeholder="search for Username or ID" value={searchValue} onChange={(e)=>updateSearch(e)}></input>
      <button className="text-zanBlue mx-3 text-[15px]" onClick={searchAlbum}>Search</button>
      <p className="inline ml-3 text-[15px]">Sort:</p>
      <select className="inline mx-3 px-3 bg-zanGrey rounded-full text-black text-[15px] h-[30px]" value={appStatus.sort} onChange={(e)=>changeSort(e)}>
        <option value={true}>Newest</option>
        <option value={false}>Oldest</option>
      </select>
    </div>
  );
}