import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { approveComments, deleteComments, getFirstCat, getNextComments, updateJobs } from "../scripts/xhrf";
import Highlighter from "react-highlight-words";

export default function CommentRateApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 5;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in comms werden die Kommentare gespeichert
  const [comms,setComments] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    typ : sessionStorage.getItem('sc')?.toLowerCase()??"feed",
    status : "new",
    note : "all",
    limit : 20,
    page : 0,
    guestId : "",
    hostId: "",
    selected : Array(0).fill(false),
    allSelected : false
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

    // Fetches Data on load
  useEffect(()=>{
    async function getData(){
      if(secondaryCategory===-1){
        return;
      }else if(sessionStorage.getItem('sc') !== "VX"){
        let ty = sessionStorage.getItem('sc')?.toLowerCase()??"feed";
        let st = appStatus.status;
        let nt = appStatus.note;
        let lt = appStatus.limit;
        let pg = appStatus.page;
        let usr = appStatus.guestId;
        let hst = appStatus.hostId;
        let c = await getNextComments(ty,st,nt,lt,pg,usr,hst);
        if(c.errors !== undefined){
          alert("Fehler: " + c.errors[0].message);
        }
        if(c.data.commentRating === null){
          setSecondaryCategory(-1);
          return;
        }
        console.log(c.data.commentRating);
        setComments(c.data.commentRating);
        setAppStatus({
          typ : ty,
          status : st,
          note : nt,
          limit : lt,
          page : pg,
          guestId : usr,
          hostId: hst,
          selected : Array(c.data.commentRating.comments.length).fill(false),
          allSelected : false
        });
        setLoading(false);
        setDoRefresh(false);
        updateJobs();
      }
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.typ,appStatus.status,appStatus.note,appStatus.limit,appStatus.page,appStatus.guestId,appStatus.hostId,doRefresh]);

  let contentF = <CommentRating comms={comms} setComments={setComments} appStatus={appStatus} setAppStatus={setAppStatus} loading={loading} setDoRefresh={setDoRefresh}/>;
      
  return(
    <div className="dark:bg-gray-800 dark:text-white w-[99vw]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No comments to rate"}</p>}
    </div>
  );
}

// Hauptkomponente für das Kommentarrating
function CommentRating({comms,setComments,appStatus,setAppStatus,loading,setDoRefresh}){

  const [guestIdPreview,setGuestIdPreview] = useState("");
  const [hostIdPreview,setHostIdPreview] = useState("");

  var maxPages = Math.ceil(comms.totalUnratedComments/Number(appStatus.limit));

  // Bewertet einen einzelnen Kommentar
  function rateComment(rat,ind){
    if(rat){
      approveComments(comms.comments[ind].commentId);
    }else{
      deleteComments(comms.comments[ind].commentId);
    }
    let c = comms;
    c.comments.splice(ind,1);
    setComments(c);
    let s = appStatus.selected;
    s.splice(ind,1);
    setAppStatus({
      ...appStatus,
      selected : s
    });
    if(c.comments.length===0){
      setDoRefresh(true);
    }
  }

  // Bewertet mehrere Kommentare
  function rateSelected(rat){
    if(rat){
      approveComments(comms.comments.filter((c,ind) => appStatus.selected[ind]).map(c=> c.commentId));
    }else{
      deleteComments(comms.comments.filter((c,ind) => appStatus.selected[ind]).map(c=> c.commentId));
    }
    let c = comms;
    let s = appStatus.selected;
    for(let i = appStatus.selected.length-1 ; i>=0 ; i--){
      if(appStatus.selected[i]){
        c.comments.splice(i,1);
        s.splice(i,1);
      }
    }
    setComments(c);
    setAppStatus({
      ...appStatus,
      selected : s
    });
    if(appStatus.allSelected){
      setDoRefresh(true);
    }
  }

  function changeStatus(e){
    setAppStatus({
      ...appStatus,
      status : e.target.value
    });
  }
  function changeNote(e){
    setAppStatus({
      ...appStatus,
      note : e.target.value
    });
  }
  function changeAmount(e){
    setAppStatus({
      ...appStatus,
      limit : e.target.value
    });
  }
  function changeGuestId(e){
    setGuestIdPreview(e.target.value);
  }
  function applyGuestSearch(){
    setAppStatus({
      ...appStatus,
      guestId : guestIdPreview
    });
    //setDoRefresh(true);
  }

  function changeHostId(e){
    setHostIdPreview(e.target.value);
  }
  function applyHostSearch(){
    setAppStatus({
      ...appStatus,
      hostId : hostIdPreview
    });
    //setDoRefresh(true);
  }

  // Wählt einen Kommentar aus
  function selectComment(e,ind){
    let l = appStatus.selected;
    l[ind] = e.target.checked;
    setAppStatus({
      ...appStatus,
      selected : l
    });
  }
  // Wählt alle Kommentare aus
  function selectAll(e){
    setAppStatus({
      ...appStatus,
      selected : appStatus.selected.fill(e.target.checked),
      allSelected : e.target.checked
    })
  }

  function firstPage(){
    setAppStatus({
      ...appStatus,
      page : 0
    });
  }
  function prevPage(){
    if(appStatus.page > 0){
      setAppStatus({
        ...appStatus,
        page : appStatus.page-1
      });
    }
  }
  function nextPage(){
    if(appStatus.page+1<maxPages){
      setAppStatus({
        ...appStatus,
        page : appStatus.page+1
      });
    }
  }
  function lastPage(){
    setAppStatus({
      ...appStatus,
      page : maxPages-1
    });
  }

  // Beinhaltet die Buttons Approve und Delete Selected
  let as = <div className="mx-2 my-2 w-[25vw]">
            <button className="inline mx-2 bg-zanGrey px-2 h-[30px] rounded-full" disabled>{"Selected "+appStatus.selected.filter(i=>i===true).length+":"}</button>
            <button className="inline mx-2 bg-lime-600 px-2 h-[30px] rounded-full text-white" onClick={(e)=>rateSelected(true)}>Approve selected</button>
            <button className="inline mx-2 bg-zanRed px-2 h-[30px] rounded-full text-white" onClick={(e)=>rateSelected(false)}>Delete selected</button>
           </div>

let tb = null;
if(loading){    // Falls geladen wird, wird keine Tabelle angezeigt
  tb = <div className="w-full h-[10vh] bg-zanBg dark:bg-gray-700">
        <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
        </div>
}else{
    tb = <table className="table-fixed border-separate text-center leading-tight">
          <thead>
            <tr className="bg-gray-400 dark:bg-gray-900">
              <th className="border-2 border-black w-[2vw]"><input className="scale-125" type={"checkbox"} onChange={(e)=>selectAll(e)} checked={appStatus.allSelected}></input></th>
              <th className="border-2 border-black w-[4vw]" colSpan={2}>Action</th>
              <th className="border-2 border-black w-[3vw]">Status</th>
              <th className="border-2 border-black w-[3vw]">Note</th>
              <th className="border-2 border-black w-[7vw]">Date</th>
              <th className="border-2 border-black w-[8vw]">Type</th>
              <th className="border-2 border-black w-[5vw]">Comment ID</th>
              <th className="border-2 border-black w-[7vw]">Host</th>
              <th className="border-2 border-black w-[7vw]">From</th>
              <th className="w-[1vw]"></th>
              <th className="border-2 border-black w-[7vw]">To</th>
              <th className="border-2 border-black w-[4vw]">Reply on</th>
              <th className="border-2 border-black w-[40vw]">Text</th>
            </tr>
          </thead>
          <tbody>
            {comms.comments.map((i,index) => <TableRow key={index} comment={i} ind={index} even={index%2===0} oc={rateComment} cb={selectComment} appStatus={appStatus}/>)}
          </tbody>
        </table>
  }

  return(
    <div className="absolute top-[10vh] w-[99vw]">
      <div>
        <p className="inline mx-2">Status:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.status} onChange={(e)=>changeStatus(e)}>
          <option value="new">NEW</option>
          <option value="verified">Verified</option>
          <option value="deleted">Deleted</option>
        </select>
        <p className="inline mx-2">Note:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black" value={appStatus.note} onChange={(e)=>changeNote(e)}>
          {appStatus.typ === "shop" && <option value="all">ALL</option>}
          {appStatus.typ === "shop" && <option value="positive">Positive</option>}
          <option value="neutral">Neutral</option>
          {appStatus.typ === "shop" && <option value="negative">Negative</option>}
        </select>
        <p className="inline mx-2">Show:</p>
        <select className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-12" value={appStatus.limit} onChange={(e)=>changeAmount(e)}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
        <p className="inline mx-2">Guest Id:</p>
        <input type="number" className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-24" value={guestIdPreview} onChange={(e)=>changeGuestId(e)}></input>
        <button className="inline mx-1 text-zanBlue w-12" onClick={applyGuestSearch}>Apply</button>
        <p className="inline mx-2">Host Id:</p>
        <input type="number" className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-24" value={hostIdPreview} onChange={(e)=>changeHostId(e)}></input>
        <button className="inline mx-1 text-zanBlue w-12" onClick={applyHostSearch}>Apply</button>
      </div>
      <div>
        <p className="inline mx-2">{`Found: ${comms.totalUnratedComments??0} feedbacks`}</p>
        <p className="inline mx-2">{`Shown: ${comms.comments?.length??0} feedbacks`}</p>
        <p className="inline mx-2">{`Page: ${Number(appStatus.page)+1} of ${maxPages}`}</p>
        <button className="inline mx-1" onClick={()=>firstPage()}>{"<<First"}</button>
        <button className="inline mx-1" onClick={()=>prevPage()}>{"<Previous"}</button>
        <button className="inline mx-1" onClick={()=>nextPage()}>{"Next>"}</button>
        <button className="inline mx-1" onClick={()=>lastPage()}>{"Last>>"}</button>
      </div>
      <div className="w-[98vw] h-0.5 mx-auto bg-gray-700"></div>
      {as}
      {tb}
      {as}
    </div>
  );
}

// Diese Komponente stellt eine einzelne Zeile in der Tabelle dar
function TableRow({comment,ind,even,oc,cb,appStatus}){
  // Kopiert die UserID in die Zwischenanlage
  function copyThat(nr){
    navigator.clipboard.writeText(nr);
  }

  return(
    <tr className={`${(even?"bg-gray-50 text-sm dark:bg-gray-500":"bg-gray-200 text-sm dark:bg-gray-700")} hover:bg-blue-100 dark:hover:bg-blue-900 h-3`}>
      <td className="border-2 border-black">
        <input className="scale-125" type={"checkbox"} onChange={(e)=>cb(e,ind)} checked={appStatus.selected[ind]}></input>
      </td>
      <td className="border-2 border-black">
        <button className="w-6" onClick={(e)=>oc(true,ind)}><img src="/icons/btn_ok.png" alt=""></img></button>
      </td>
      <td className="border-2 border-black">
        <button className="w-6" onClick={(e)=>oc(false,ind)}><img src="/icons/btn_delete.png" alt=""></img></button>
      </td>
      <td className="border-2 border-black">{comment.status}</td>
      <td className={`border-2 border-black ${(comment.note==="positive" ? "bg-lime-300 " : (comment.note==="negative" ? "bg-red-300":""))}`}>{comment.note}</td>
      <td className="border-2 border-black">{comment.date.substring(0,10) + " " + comment.date.substring(11,16)}</td>
      <td className="border-2 border-black">{comment.type}</td>
      <td className="border-2 border-black">{comment.commentId}</td>
      <td className="border-2 border-black hover:text-blue-800" title={"Klicken zum kopieren von ID: "+comment.modelId} onClick={()=>copyThat(comment.modelId)}>{comment.modelName}</td>
      <td className="border-2 border-black hover:text-blue-800" title={"Klicken zum kopieren von ID: "+comment.forwarderId} onClick={()=>copyThat(comment.forwarderId)}>{comment.forwarderName}</td>
      <td className="font-bold text-xl">&#x21E8;</td>
      <td className="border-2 border-black hover:text-blue-800" title={"Klicken zum kopieren von ID: "+comment.recipientId} onClick={()=>copyThat(comment.recipientId)}>{comment.recipientName}</td>
      <td className="border-2 border-black">{comment.replyOn}</td>
      <td className="border-2 border-black text-left">
        <Highlighter 
          searchWords={comment.suspectedWords}
          textToHighlight ={comment.text}
          className={""}
        />
      </td>
    </tr>
  );
}