import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { get0900, getCookie, getFirstCat, rate0900, updateJobs } from "../scripts/xhrf";

export default function ZeroNinehundredApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 16;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in comms werden die Kommentare gespeichert
  const [numbers,setNumbers] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    approved: []
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

    // Fetches Data on load
  useEffect(()=>{
    async function getData(){
      let c = await get0900();
      if(c.errors !== undefined){
        alert("Fehler: " + c.errors[0].message);
      }
      if(c.data.service0900 === null){
        setSecondaryCategory(-1);
        return;
      }
      console.log(c.data.service0900);
      setNumbers(c.data.service0900);
      setAppStatus({
        approved : c.data.service0900.items.map((i) => i.isTargetNumberVerified)
      });
      setLoading(false);
      setDoRefresh(false);
      updateJobs();
    }
    setLoading(true);
    getData();
  },[secondaryCategory,doRefresh]);

  async function rateNumbers(){
    await rate0900(numbers.items.map((i,ind) => JSON.parse(`{"id": ${i.account.userId}, "isAccepted": ${appStatus.approved[ind]}}`)));
    setDoRefresh(true);
  }

  let contentF = <ContentField appStatus={appStatus} setAppStatus={setAppStatus} loading={loading} numbers={numbers} rateNumbers={rateNumbers}/>;
      
  return(
    <div className="dark:bg-gray-800 dark:text-white w-[99vw]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No comments to rate"}</p>}
    </div>
  );
}

function ContentField({appStatus,setAppStatus,loading,numbers,rateNumbers}){

  function setApproval(ind,appr){
    let a = appStatus.approved;
    a[ind] = appr;
    setAppStatus({
      approved: a
    })
  }

  let tb = null;
  if(loading){    // Falls geladen wird, wird keine Tabelle angezeigt
    tb = <div className="w-full h-[10vh] bg-zanBg dark:bg-gray-700">
          <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  }else{
    tb = <table className="table-fixed border-separate text-center leading-tight">
          <thead>
            <tr className="bg-gray-400 dark:bg-gray-900">
              <th className="border-2 border-black w-[14vw]"></th>
              <th className="border-2 border-black w-[10vw]">Username</th>
              <th className="border-2 border-black w-[12vw]">Target</th>
              <th className="border-2 border-black w-[30vw]">Products</th>
            </tr>
          </thead>
          <tbody>
            {numbers.items.map((i,index) => <TableRow key={index} number={i} ind={index} even={index%2===0} approved={appStatus.approved[index]} setApproval={setApproval}/>)}
          </tbody>
        </table>;
      
    }

  return(
    <div className="absolute top-[10vh] w-[99vw]">
      <div>
        <p className="inline mx-2">{`Found: ${numbers.totalItems??0} check(s)`}</p>
      </div>
      <div className="w-[98vw] h-0.5 mx-auto bg-gray-700"></div>
      {tb}
      <button className="relative left-[25.5vw] top-2 w-[15vw] h-10 bg-lime-600 text-white rounded-3xl" onClick={rateNumbers}>
        Save 0900 Numbers
      </button>
    </div>
  );
}

function TableRow({number,ind,even,approved,setApproval}){
  
  function generateProductList(){
    let currentProduct = "";
    let output = [];
    let iterator = 0;
    for(let n in number.numbers0900){
      if(number.numbers0900[n].product !== currentProduct){
        output.push(<p key={iterator}>Product: <span className="font-bold">{number.numbers0900[n].product}</span></p>);
        currentProduct = number.numbers0900[n].product;
        iterator++;
      }
      output.push(<p key={iterator} className="pl-3">{number.numbers0900[n].country}: {number.numbers0900[n].phoneNumber}</p>);
      iterator++;
    }
    return output;
  }

  return(
    <tr className={`${(even?"bg-gray-50 text-sm dark:bg-gray-500":"bg-gray-200 text-sm dark:bg-gray-700")} dark:hover:bg-blue-900 h-3`}>
      <td className="border-2 border-black w-[10vw] align-top pt-2">
        <div className="inline mx-3">
          <img className="inline h-5 w-5" alt="" src={approved ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>setApproval(ind,true)}></img>
          <p className="inline ml-2 text-sm text-lime-600" onClick={()=>setApproval(ind,true)}>Approve</p>
        </div>
        <div className="inline mx-3">
          <img className="inline h-5 w-5" alt="" src={!approved ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>setApproval(ind,false)}></img>
          <p className="inline ml-2 text-sm text-zanRed" onClick={()=>setApproval(ind,false)}>Delete</p>
        </div>
      </td>
      <td className="border-2 border-black align-top pt-2 font-bold">{getCookie('internal') === "X" ? <a target="_blank" rel="noreferrer" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+number.account.userId}>{number.account.userName}</a> 
      : <p>{number.account.userName}</p>}</td>
      <td className="border-2 border-black w-[10vw] align-top pt-2 font-bold"><p>{number.targetNumber}</p><span className="text-zanRed">{(number.isTargetNumberVerified ? "" : "Not yet verified")}</span></td>
      <td className="border-2 border-black w-[30vw] text-left pl-4 pt-2 pb-2">{generateProductList()}</td>
    </tr>
  );
}