import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import {getCookie, getDatingCheck, getFirstCat, rateDatingCheck, updateJobs } from "../scripts/xhrf";

export default function DatingNicknameApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig.
  const ratingCat = 12;

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // in comms werden die Kommentare gespeichert
  const [nicks,setNicks] = useState({});

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    userId : ""
  });

  // Status zum anzeigen der Ladeanimation
  const [loading,setLoading] = useState(true);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

    // Fetches Data on load
  useEffect(()=>{
    async function getData(){
      if(secondaryCategory===-1){
        return;
      }
      let uid = appStatus.userId;
      let c = await getDatingCheck(uid??"");
      if(c.errors !== undefined){
        alert("Fehler: " + c.errors[0].message);
      }
      if(c.data.communityNicknameCheck === null){
        setSecondaryCategory(-1);
        return;
      }
      console.log(c.data.communityNicknameCheck);
      setNicks(c.data.communityNicknameCheck);
      setAppStatus({
        userId : appStatus.userId
      });
      setLoading(false);
      setDoRefresh(false);
      updateJobs();
    }
    setLoading(true);
    getData();
  },[secondaryCategory,appStatus.userId,doRefresh]);

  async function rateNick(uid,isValid){
    await rateDatingCheck([{id: uid, accepted: isValid}]);
    setDoRefresh(true);
  }

  async function rateAllNicks(){
    await rateDatingCheck(nicks.items.map((n,ind)=>JSON.parse(`{"id": ${n.account.userId}, "accepted": true}`)));
    setDoRefresh(true);
  }

  let contentF = <ContentField appStatus={appStatus} setAppStatus={setAppStatus} loading={loading} nicks={nicks} rateNick={rateNick} rateAllNicks={rateAllNicks}/>;
      
  return(
    <div className="dark:bg-gray-800 dark:text-white w-[99vw]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />
      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No comments to rate"}</p>}
    </div>
  );
}

function ContentField({appStatus,setAppStatus,loading,nicks,rateNick,rateAllNicks}){

  const [userIdPreview,setUserIdPreview] = useState("");

  function applyUserSearch(){
    setAppStatus({
      userId : userIdPreview
    });
  }

  let tb = null;
  if(loading){    // Falls geladen wird, wird keine Tabelle angezeigt
    tb = <div className="w-full h-[10vh] bg-zanBg dark:bg-gray-700">
          <img className="h-[5vw] w-[5vw] left-[47.5vw] top-0 relative blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
          </div>
  }else{
    tb = <table className="table-fixed border-separate text-center leading-tight">
          <thead>
            <tr className="bg-gray-400 dark:bg-gray-900">
              <th className="border-2 border-black w-[8vw]">Host-Login</th>
              <th className="border-2 border-black w-[8vw]">Host-ID</th>
              <th className="border-2 border-black w-[10vw]">Community nickname</th>
              <th className="border-2 border-black w-[10vw]">Alternative nickname</th>
              <th className="border-2 border-black w-[5vw]">Status</th>
              <th className="border-2 border-black w-[8vw]">Status change date</th>
              <th className="border-2 border-black w-[12vw]">Rating</th>
            </tr>
          </thead>
          <tbody>
            {nicks.items.map((i,index) => <TableRow key={index} check={i} ind={index} even={index%2===0} oc={rateNick}/>)}
          </tbody>
          <button className="relative left-[29vw] bg-lime-600 text-white h-12 w-44 text-2xl font-bald rounded-3xl" onClick={rateAllNicks}>
            Accept All
          </button>
        </table>
    }

  return(
    <div className="absolute top-[10vh] w-[99vw]">
      <div>
        <p className="inline mx-2">User Id:</p>
        <input type="number" className="inline mx-1 bg-zanGrey rounded-full h-[30px] text-black w-24" value={userIdPreview} onChange={(e)=>setUserIdPreview(e.target.value)}></input>
        <button className="inline mx-1 text-zanBlue w-12" onClick={()=>applyUserSearch()}>Apply</button>
      </div>
      <div>
        <p className="inline mx-2">{`Found: ${nicks.totalItems??0} check(s)`}</p>
      </div>
      <div className="w-[98vw] h-0.5 mx-auto bg-gray-700"></div>
      {tb}
    </div>
  );
}

function TableRow({check,ind,even,oc}){
  return(
    <tr className={`${(even?"bg-gray-50 text-sm dark:bg-gray-500":"bg-gray-200 text-sm dark:bg-gray-700")} hover:bg-blue-100 dark:hover:bg-blue-900 h-3`}>
      <td className="border-2 border-black w-[8vw]">{getCookie('internal') === "X" ? <a target="_blank" rel="noreferrer" href={"https://vxadmin.ops.xxx/CONTROLVX/HostSummary?usrid="+check.account.userId}>{check.account.userName}</a>
        : <p>{check.account.userName}</p>}</td>
      <td className="border-2 border-black w-[8vw]">{check.account.userId}</td>
      <td className="border-2 border-black w-[10vw] font-bold">{check.nickname}</td>
      <td className="border-2 border-black w-[10vw]"></td>
      <td className="border-2 border-black w-[5vw]">{check.status}</td>
      <td className="border-2 border-black w-[8vw]">{check.changedAt}</td>
      <td className="border-2 border-black w-[12vw]">
        <button className="mx-7 text-lime-600" onClick={()=>oc(check.account.userId,true)}>Accept</button>
        <button className="mx-7 text-zanRed" onClick={()=>oc(check.account.userId,false)}>Reject</button>
      </td>
    </tr>
  );
}