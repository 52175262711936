import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './css/output.css';
import LoginApp from './pages/Login';
import HomeApp from './pages/HomeApp';
import PhotoRateApp from './pages/PhotoRating';
import VideoRateApp from './pages/VideoRating';
import HelpPageApp from './pages/HelpPage';
import CommentRateApp from './pages/CommentRating';
import TextRateApp from './pages/TextRating';
import ModelVerificationApp from './pages/ModelVerification';
import Fsk16ChatReviewApp from './pages/Fsk16ChatReview';
import StreamMonitorApp from './pages/StreamMonitor';
import Fix from './pages/Temporaryfix';
import LoginFitnessApp from './pages/LoginFitnessCheck';
import DatingNicknameApp from './pages/DatingNicknameCheck';
import PowerMailingApp from './pages/PowerMailing';
import BadwordsApp from './pages/Badwords';
import ZeroNinehundredApp from './pages/ZeroNinehundred';

export default function App(){
  return(
    <div className="App">
      <header className="App-header">
      </header>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index Component={()=>{window.location.href="/login"}}></Route>
            <Route index path="login" element={<LoginApp />}></Route>
            <Route path="bugfix" element={<Fix />}></Route>
            <Route path="home" element={<HomeApp />}></Route>
            <Route path="photoRating/*" element={<PhotoRateApp />}></Route>
            <Route path="videoRating/*" element={<VideoRateApp />}></Route>
            <Route path="commentRating" element={<CommentRateApp />}></Route>
            <Route path="textRating" element={<TextRateApp />}></Route>
            <Route path="loginFitness" element={<LoginFitnessApp />}></Route>
            <Route path="datingNicknames" element={<DatingNicknameApp />}></Route>
            <Route path="modelVerification/*" element={<ModelVerificationApp />}></Route>
            <Route path="fsk16Review" element={<Fsk16ChatReviewApp />}></Route>
            <Route path="streamMonitor" element={<StreamMonitorApp />}></Route>
            <Route path="powerMailing" element={<PowerMailingApp />}></Route>
            <Route path="badwords" element={<BadwordsApp />}></Route>
            <Route path="zeroninehundred" element={<ZeroNinehundredApp />}></Route>
            <Route path="helpPage" element={<HelpPageApp />}></Route>
            <Route path="*" element={<NotFound/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Die 404 Seite
function NotFound(){
  return(
    <h1 className='m-4 text-3xl font-bold'>404 Not found</h1>
  );
}